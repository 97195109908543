import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { Button, ConfigProvider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import YetiSync from './YetiSync';

// Import icons
import AgricultureIcon from '@mui/icons-material/Agriculture';
import EventIcon from '@mui/icons-material/Event';
import HistoryIcon from '@mui/icons-material/History';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RouteIcon from '@mui/icons-material/Route';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import EngineeringIcon from '@mui/icons-material/Engineering';
import MapIcon from '@mui/icons-material/Map';
import { Grading } from '@mui/icons-material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DashboardIcon from '@mui/icons-material/Dashboard';

const SideMenu = () => {
  const [isYetiDataOpen, setIsYetiDataOpen] = useState(false);
  const [yetiSyncOpen, setYetiSyncOpen] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  const handleYetiSyncOpen = () => {
    setYetiSyncOpen(true);
  };

  const handleYetiSyncClose = () => {
    setYetiSyncOpen(false);
  };

  const toggleYetiData = () => {
    setIsYetiDataOpen(!isYetiDataOpen);
  }

  return (
    <ConfigProvider
        theme={{
            token: {
            colorPrimary: '#F57D20',
            },
        }}
        >
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px',
      width: '250px',
    }}>
      <List component="nav" style={{ width: '250px', padding: '0 16px' }}>
       <ListItem disablePadding>
          <ListItemButton component={Link} to="/dashboard">
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/events">
            <ListItemIcon><EventIcon /></ListItemIcon>
            <ListItemText primary="Events" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/notify">
            <ListItemIcon><NotificationsIcon /></ListItemIcon>
            <ListItemText primary="Notify Clients" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/dispatch">
            <ListItemIcon><RouteIcon /></ListItemIcon>
            <ListItemText primary="Dispatch Routes" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/eventhistory">
            <ListItemIcon><HistoryIcon /></ListItemIcon>
            <ListItemText primary="Event History" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/weatherhistory">
            <ListItemIcon><HistoryIcon /></ListItemIcon>
            <ListItemText primary="Weather History" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/purchase-orders">
            <ListItemIcon><Grading /></ListItemIcon>
            <ListItemText primary="Purchase Orders" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/invoices">
            <ListItemIcon><ReceiptIcon /></ListItemIcon>
            <ListItemText primary="Invoices" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={toggleYetiData}>
            <ListItemIcon><DataUsageIcon /></ListItemIcon>
            <ListItemText primary="Yeti Data" />
            {isYetiDataOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        {isYetiDataOpen && (
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/sites" sx={{ pl: 4 }}>
                <ListItemIcon><BusinessIcon /></ListItemIcon>
                <ListItemText primary="Sites" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/clients" sx={{ pl: 4 }}>
                <ListItemIcon><PeopleIcon /></ListItemIcon>
                <ListItemText primary="Clients" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/subcontractors" sx={{ pl: 4 }}>
                <ListItemIcon><EngineeringIcon /></ListItemIcon>
                <ListItemText primary="Subcontractors" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/routes" sx={{ pl: 4 }}>
                <ListItemIcon><MapIcon /></ListItemIcon>
                <ListItemText primary="Routes" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/servicehistory" sx={{ pl: 4 }}>
                <ListItemIcon><HistoryIcon /></ListItemIcon>
                <ListItemText primary="Service History" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/equipment" sx={{ pl: 4 }}>
                <ListItemIcon><AgricultureIcon /></ListItemIcon>
                <ListItemText primary="Equipment" />
              </ListItemButton>
            </ListItem>
          </List>
        )}
        <ListItem disablePadding style={{ marginTop: '10px', marginLeft: '10px' }}>
          <Button
            type="primary"
            onClick={handleYetiSyncOpen}
            disabled={isSyncing}
            style={{ width: '80%' }}
          >
            {isSyncing ? (
              <>
                Importing Data <Spin
                  indicator={<LoadingOutlined spin />}
                  style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '8px' }}
                />
              </>
            ) : (
              'Import All Data'
            )}
          </Button>
        </ListItem>
      </List>

      <YetiSync open={yetiSyncOpen} onClose={handleYetiSyncClose} isSyncing={setIsSyncing} />
    </div>
    </ConfigProvider>
  );
};

export default SideMenu;
