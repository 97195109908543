import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchLastActiveEvent, fetchEvent } from '../api';

const CurrentEventContext = createContext();

export const CurrentEventProvider = ({ children }) => {
  const [currentEvent, setCurrentEvent] = useState(null);

  useEffect(() => {
    const initializeEvent = async () => {
      try {
        const lastEventData = await fetchLastActiveEvent();
        if (lastEventData) {
          setCurrentEvent(lastEventData); 
        }
      } catch (error) {
        console.error('Error initializing the last event:', error);
      }
    };

    initializeEvent();
  }, []);

  const updateCurrentEvent = async (eventId) => {
    try {
      const eventData = await fetchEvent(eventId);
      setCurrentEvent(eventData);
    } catch (error) {
      console.error(`Error fetching event ${eventId}:`, error);
    }
  };

  return (
    <CurrentEventContext.Provider value={{ currentEvent, setCurrentEvent: updateCurrentEvent }}>
      {children}
    </CurrentEventContext.Provider>
  );
};

export const useCurrentEvent = () => {
  const context = useContext(CurrentEventContext);
  if (context === undefined) {
    throw new Error('useCurrentEvent must be used within a CurrentEventProvider');
  }
  return context;
};