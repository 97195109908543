import React, { useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import { addSubcontractorEquipment, fetchEquipment, getSubcontractorEquipment, deleteSubcontractorEquipment } from '../api';
import {
  TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Select, MenuItem,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, InputLabel
} from '@mui/material';

import { Button as AntButton } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import SideMenu from '../components/SideMenu';

function SubcontractorEquipmentPage() {
    const [equipment, setEquipment] = useState([]);
    const [subEquipment, setSubEquipment] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { subcontractor_id } = useParams();
    const [hourlyRate, setHourlyRate] = useState(0);
    const [hourlyRateError, setHourlyRateError] = useState(false);
    const [selectedEquipment, setSelectedEquipment] = useState(0);
    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setHourlyRate(0);
        setHourlyRateError(false);
        setSelectedEquipment("");
    };
    const onHandleEquipmentChange = e => {
        setSelectedEquipment(e.target.value);
        for (const equip of equipment) {
          if (equip.id == e.target.value) {
            setHourlyRate(equip.custom_contractor_rate ? equip.custom_contractor_rate : equip.contractor_rate);
            break;
          }
        }
    }
    const handleDialogRateChange = e => {
        setHourlyRate(e.target.value);
        if (e.target.value >=0) {
            setHourlyRateError(false);
        } else {
            setHourlyRateError(true);
        }
    }

    // API //
    useEffect(() => {
        loadEquipment();
        loadSubcontractorEquipment();
      }, []);
    const loadEquipment = async () => {
      try {
        const data = await fetchEquipment();
        setEquipment(data);
      } catch (err) {
        console.log("error fetching equipment");
      }
    };
    const loadSubcontractorEquipment = async () => {
      try {
        const data = await getSubcontractorEquipment(subcontractor_id);
        setSubEquipment(data);
      } catch (err) {
        console.log("error fetching subcontractor equipment")
      }
    }
    const handleAddSubcontractorEquipment = async () => {
      try {
        await addSubcontractorEquipment(subcontractor_id, selectedEquipment, hourlyRate);
        loadSubcontractorEquipment();
        handleCloseDialog();
      } catch (error) {
        console.error('Failed to update subcontractor equipment:', error);
      }
    };
    const deleteSubEquip = async (subId, subEquipId) => {
      try {
        await deleteSubcontractorEquipment(subId, subEquipId);
        loadSubcontractorEquipment();
      } catch (error) {
        console.error('Failed to delete subcontractor equipment:', error);
      }
    }

  return (
    <Grid container spacing={2} style={{ height: '100vh' }}>
      <Grid item style={{ width: '216px' }}>
        <SideMenu />
      </Grid>
      <Grid item xs>
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
        >
            <DialogTitle>Add Custom Equipment Rate</DialogTitle>
            <DialogContent>
                <InputLabel id="equipment-name">Equipment Name</InputLabel>
                <Select
                    value={selectedEquipment}
                    onChange={onHandleEquipmentChange}
                    labelId="equipment-name"
                    fullWidth
                    margin="normal"
                >
                {equipment.filter((equip) => {
                  return !subEquipment.some((subEquip) => {
                    // Remove the equipment that already have a
                    // custom rate for this subcontractor.
                    return subEquip.name === equip.name
                  })}).map((eq) => (
                  <MenuItem key={eq.id} value={eq.id}>
                      {eq.name}
                  </MenuItem>
                ))}
                </Select>
                <TextField
                    fullWidth
                    margin="normal"
                    name="hourly_rate"
                    label="Hourly Rate"
                    type="number"
                    value={hourlyRate}
                    onChange={handleDialogRateChange}
                    error={hourlyRateError}
                    helperText={hourlyRateError ? "Must be positive number" : ""}
                />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseDialog}
              >
                Cancel
            </Button>
              <Button
                onClick={handleAddSubcontractorEquipment}
                disabled={hourlyRateError}
              >
                Add
            </Button>
            </DialogActions>
          </Dialog>

        <TableContainer component={Paper} sx={{ marginTop: 4, marginBottom: 4, maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="h4">Subcontractor Equipment</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                >
                    Add Custom Equipment Rate
                </Button>
            </Box>
            <Typography variant="p">
                <Link to={'/subcontractors'}>
                    Back to All Subcontractors
                </Link>
            </Typography>
          </Box>
          <Table aria-label="equipment-table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Hourly Rate</TableCell>
                <TableCell>Yeti Equipment ID</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subEquipment.map((equip) => (
                <TableRow key={equip.id}>
                <TableCell>{equip.name}</TableCell>
                <TableCell>$ {equip.hourly_rate.toFixed(2)}</TableCell>
                <TableCell>{equip.external_id}</TableCell>
                <TableCell>
                  <AntButton onClick={() => deleteSubEquip(equip.subcontractor_id, equip.id)} type="text" icon={<DeleteOutlined />}/>
                </TableCell>
            </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SubcontractorEquipmentPage;
