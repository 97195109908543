// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, getProfile } from '../api';
import { Snackbar, Alert, Typography } from '@mui/material';
import { Input, Button, ConfigProvider } from 'antd';
import { MailOutlined, KeyOutlined } from '@ant-design/icons';
import { useUser } from '../context/UserContext';
import { motion } from 'framer-motion';
import '../styles/LoginPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const { updateUser } = useUser();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await login(email, password);
      const userProfile = await getProfile();
      updateUser({ ...userProfile, authenticated: true });
      navigate('/');
    } catch (error) {
      setNotificationMessage('Incorrect email or password. Please try again.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F57D20',
        },
      }}
    >
    <div className="container">
      <motion.div 
        className="main"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          type: "spring",
          stiffness: 150,
          damping: 30,
        }}
      >
        <div className="textStack">
          <span className="title">Welcome Back!</span>
          <span className="subtitle">
            Please enter your details to sign in to IOS.
          </span>
          <Typography className="forgotPassword" onClick={() => navigate('/forgot-password')}>
            Forgot Password?
          </Typography>
        </div>
        <div className="fieldStack">
          <Input
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)', marginRight: '5px' }} />}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress} 
            className='textField'
          />
          <Input.Password
            prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)', marginRight: '5px' }} />}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            className='textField'
          />
        </div>
        <Button type="primary" onClick={handleLogin} className="submitButton" block>Sign In</Button>
      </motion.div>
      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={() => setNotificationOpen(false)}
      >
        <Alert onClose={() => setNotificationOpen(false)} severity={notificationSeverity}>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </div>
    </ConfigProvider>
  );
};

export default LoginPage;