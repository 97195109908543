// ServiceHistoryPage.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { ConfigProvider, Button, Table } from 'antd';

import SideMenu from '../components/SideMenu';
import { fetchServiceHistories } from '../api';
import { formatUtcDateTime } from '../utils/dateUtils';

const ServiceHistoryPage = () => {
  const [serviceHistory, setServiceHistory] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 

  useEffect(() => {
    loadServiceHistory();
  }, []);

  const loadServiceHistory = async () => {
    try {
      const data = await fetchServiceHistories();
      console.log(data);
      setServiceHistory(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Route Name',
      dataIndex: 'route_name',
      key: 'route_name',
    },
    {
      title: 'Site Name',
      dataIndex: 'site_name',
      key: 'site_name',
    },
    {
      title: 'Services',
      dataIndex: 'services',
      key: 'services',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Started At',
      dataIndex: 'started_at',
      key: 'started_at',
      render: (text) => formatUtcDateTime(text),
    },
    {
      title: 'Ended At',
      dataIndex: 'ended_at',
      key: 'ended_at',
      render: (text) => formatUtcDateTime(text),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Missing Data Flag',
      key: 'missing_data_flag',
      render: (_, { missing_data_flag }) => missing_data_flag ? 'Yes' : 'No',
    },
  ];

  if (error) {
    return <Typography variant="h6" color="error">Error: {error}</Typography>;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F57D20',
        },
      }}
    >
      <Box display="flex" sx={{ width: '98vw', marginTop: '20px' }}>
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        <Box flexGrow={1}>
          <Card style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <CardContent>
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Typography variant="h5" component="div">
                  Service History
                </Typography>
              </Box>
              <Table columns={columns} dataSource={serviceHistory} rowKey="id" style={{ marginTop: '20px' }} />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </ConfigProvider>
  );
};

export default ServiceHistoryPage;
