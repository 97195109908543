import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Select, DatePicker, Typography, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchEvents, syncServiceHistories } from '../api';
import dayjs from 'dayjs';

const { Option } = Select;
const { RangePicker } = DatePicker;

const SyncServiceHistories = ({ open, onClose }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [isSyncDisabled, setIsSyncDisabled] = useState(true);
  const [syncForm] = Form.useForm();
  const [startTimeDisabled, setStartTimeDisabled] = useState(false);
  const [syncSummary, setSyncSummary] = useState(null);
  const [modalView, setModalView] = useState('syncForm'); // 'syncForm', 'loading', or 'summary'

  const customSpinIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  useEffect(() => {
    const loadEvents = async () => {
      setEventsLoading(true);
      try {
        const response = await fetchEvents();
        setEvents(response);
      } catch (error) {
        message.error('Failed to fetch events');
        console.error('Error fetching events:', error);
      } finally {
        setEventsLoading(false);
      }
    };

    if (open) {
      loadEvents();
    }
  }, [open]);

  const handleValuesChange = (changedValues, allValues) => {
    const { timeRange } = allValues;
    if (timeRange && timeRange[0]) {
      setIsSyncDisabled(false);
    } else {
      setIsSyncDisabled(true);
    }
  };

  const handleEventSelection = (eventId) => {
    if (eventId === 'none') {
      syncForm.setFieldsValue({
        timeRange: [null, null]
      });
      setStartTimeDisabled(false);
      setIsSyncDisabled(true);
    } else {
      const selectedEvent = events.find(event => event.event_id === eventId);
      if (selectedEvent) {
        syncForm.setFieldsValue({
          timeRange: [dayjs(selectedEvent.event_start), null]
        });
        setStartTimeDisabled(true);
        setIsSyncDisabled(false);
      }
    }
  };

  const handleSyncServiceHistories = async (values) => {
    const { timeRange } = values;
    const startTime = timeRange ? timeRange[0].toISOString() : null;
    const endTime = timeRange && timeRange[1] ? timeRange[1].toISOString() : null; // Allow endTime to be null

    try {
      setLoading(true);
      setModalView('loading');
      const response = await syncServiceHistories(startTime, endTime);
      setSyncSummary(response);
      setModalView('summary');
      message.success('Service histories synced successfully!');
    } catch (error) {
      setModalView('syncForm');
      message.error('Failed to sync service histories');
      console.error('Error syncing service histories:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSummaryModalClose = () => {
    setModalView('syncForm');
    syncForm.resetFields();
    setSyncSummary(null);
    onClose();
  };

  const renderSyncForm = () => (
    <>
      <Typography style={{ marginBottom: '15px' }}>
        Select an event to sync service histories since the event start time.
        If no event is selected, you can provide a custom start time.
        The end time is optional and can be left blank to sync all service histories since the start time.
        Syncing service histories attempts to match uncaptured reports with invoices and purchase orders.
      </Typography>

      <Form
        form={syncForm}
        layout="vertical"
        onFinish={handleSyncServiceHistories}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          label="Select Event (Optional)"
          name="event"
        >
          <Select
            placeholder="Select an event"
            onChange={handleEventSelection}
            allowClear
            loading={eventsLoading}
          >
            <Option key="none" value="none">
              None
            </Option>
            {events.map((event) => (
              <Option key={event.event_id} value={event.event_id}>
                {event.event_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Time Range"
          name="timeRange"
        >
          <RangePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            placeholder={['Start Time', 'End Time (Optional)']}
            disabled={[startTimeDisabled, false]}
            allowEmpty={[false, true]}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit" disabled={isSyncDisabled}>
          Sync
        </Button>
      </Form>
    </>
  );

  const renderLoadingView = () => (
    <div style={{ textAlign: 'center' }}>
      <Spin indicator={customSpinIcon} size="large" />
      <Typography.Title level={4}>Syncing Service Histories...</Typography.Title>
    </div>
  );

  const renderSummaryView = () => (
    <>
      <Typography.Title level={4}>Matched Service Histories</Typography.Title>
      <Typography.Paragraph>
        {syncSummary.matched_service_histories_count > 0
          ? syncSummary.matched_service_histories.map((sh, idx) => (
              <div key={idx}>
                <strong>Service History ID:</strong> {sh.id}<br />
                <strong>Invoice ID:</strong> {sh.invoice_id}<br />
                <strong>Purchase Order ID:</strong> {sh.po_id}<br />
                <strong>Event:</strong> {sh.event_name}<br />
                <strong>Route:</strong> {sh.route_name}<br />
                <strong>Site:</strong> {sh.site_name}<br />
                <strong>Subcontractor:</strong> {sh.sub_contractor_name}<br />
                <strong>Ended At:</strong> {sh.ended_at}<br />
                <hr />
              </div>
            ))
          : "No matched service histories."}
      </Typography.Paragraph>

      <Typography.Title level={4}>Unmatched Service Histories</Typography.Title>
      <Typography.Paragraph>
        {syncSummary.unmatched_service_histories_count > 0
          ? syncSummary.unmatched_service_histories.map((sh, idx) => (
              <div key={idx}>
                <strong>Service History ID:</strong> {sh.id}<br />
                <strong>Dispatcher Notes:</strong> {sh.event_name} {'(could not find event name matching this)'}<br />
                <strong>Site:</strong> {sh.site_name}<br />
                <strong>Subcontractor:</strong> {sh.sub_contractor_name}<br />
                <strong>Ended At:</strong> {sh.ended_at}<br />
                <hr />
              </div>
            ))
          : "No unmatched service histories."}
      </Typography.Paragraph>

      {/* Conditionally render errors if present */}
      {syncSummary.errors && syncSummary.errors.length > 0 && (
        <>
          <Typography.Title level={4} type="danger">Errors</Typography.Title>
          <Typography.Paragraph>
            {syncSummary.errors.map((error, idx) => (
              <div key={idx}>
                <strong>Error:</strong> {error}<br />
                <hr />
              </div>
            ))}
          </Typography.Paragraph>
        </>
      )}
    </>
  );

  return (
    <Modal
      title="Sync Service Histories"
      open={open}
      onCancel={handleSummaryModalClose}
      footer={null}
    >
      <Spin spinning={eventsLoading}>
        {modalView === 'syncForm' && renderSyncForm()}
        {modalView === 'loading' && renderLoadingView()}
        {modalView === 'summary' && renderSummaryView()}
      </Spin>
    </Modal>
  );
};

export default SyncServiceHistories;