import React, { useState, useEffect } from 'react';
import { fetchRoutes, updateRouteDetails, fetchRouteSubcontractors } from '../api';
import { Typography, Box, Card, CardContent } from '@mui/material';
import SideMenu from '../components/SideMenu';
import { ConfigProvider, Table, Input, Select, Modal } from 'antd';
import { SearchOutlined, EditOutlined } from '@ant-design/icons'

const RoutesPage = () => {
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [subcontractors, setSubcontractors] = useState([]);
  const [error, setError] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editRoute, setEditRoute] = useState(null);
  const [selectedSubcontractor, setSelectedSubcontractor] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('route_name');
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    loadRoutes();
  }, []);

  useEffect(() => {
    const filteredData = routes.filter(route => {
      const routeName = route.route_name || 'N/A';
      const zoneName = route.zone_name || 'N/A';
  
      return (
        routeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        zoneName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  
    setFilteredRoutes(filteredData);
  }, [searchQuery, routes]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRoutes = filteredRoutes.sort((a, b) => {
    const aValue = (a[orderBy] ?? 'N/A').toString();
    const bValue = (b[orderBy] ?? 'N/A').toString();
  
    return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });

  const loadRoutes = async () => {
    try {
      const data = await fetchRoutes();
      console.log('routes data:', data);
      setRoutes(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEditClick = async (route) => {
    try {
      const subcontractorData = await fetchRouteSubcontractors(route.route_id);
  
      setEditRoute(route);
      setSelectedSubcontractor(route.default_subcontractor_id || null);
      setSubcontractors(subcontractorData);
  
      setEditModalVisible(true);
    } catch (error) {
      console.error('Error fetching subcontractors or route data:', error);
      setError('Failed to load route details');
    }
  };

  const handleSubcontractorChange = (value) => {
    setSelectedSubcontractor(value);
  };

  const saveRouteDetails = async () => {
    try {
      await updateRouteDetails(editRoute.route_id, {
        default_subcontractor_id: selectedSubcontractor,
      });
      loadRoutes();
      setEditModalVisible(false);
    } catch (error) {
      console.error('Error updating route details:', error);
      setError('Failed to update route details');
    }
  };

  const handleExpand = (expanded, route) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, route.route_id]);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== route.route_id));
    }
  };

  const expandedRowRender = (route) => {
    const siteColumns = [
      {
        title: 'Site Name',
        dataIndex: 'site_name',
        key: 'site_name',
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: 'Client Name',
        dataIndex: 'client_name',
        key: 'client_name',
      },
    ];

    return (
      <Table
        columns={siteColumns}
        dataSource={route.sites}
        pagination={false}
        rowKey={(site) => site.site_id}
        style={{ marginLeft: '32px' }}
      />
    );
  };

  const routeColumns = [
    {
      title: 'Route Name',
      dataIndex: 'route_name',
      key: 'route_name',
      sorter: true,
      sortOrder: orderBy === 'route_name' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('route_name') }),
      render: (routeName) => (
        <Typography>{routeName}</Typography>
      ),
    },
    {
      title: 'Default Subcontractor',
      key: 'default_subcontractor_name',
      sorter: true,
      sortOrder: orderBy === 'default_subcontractor_name' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('default_subcontractor_name') }),
      render: (record) => (
        <Typography>{record.default_subcontractor_name || 'None'}</Typography>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: '10%',
      fixed: 'right',
      render: (record) => (
        <EditOutlined
          onClick={() => handleEditClick(record)}
          style={{
            fontSize: '18px',
            cursor: 'pointer',
            color: 'lightgrey',
            transition: 'color 0.3s',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = '#f57e1f')}
          onMouseLeave={(e) => (e.currentTarget.style.color = 'lightgrey')}
        />
      ),
    },
  ];

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#F57D20' } }}>
      <Box display="flex" sx={{ height: '100%' }}>
        {/* Side Menu */}
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>

        {/* Main Content */}
        <Box flexGrow={1} sx={{ display: 'flex', flexDirection: 'column', padding: '16px', overflow: 'hidden' }}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            {/* Header Section */}
            <CardContent sx={{ flexShrink: 0, padding: '16px 24px', borderBottom: '1px solid #f0f0f0' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Routes</Typography>
                <Input
                  placeholder="Search Routes"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{ width: '300px' }}
                  prefix={<SearchOutlined style={{ color: '#d9d9d9' }} />}
                />
              </Box>
            </CardContent>

            {/* Table Section */}
            <Box sx={{ flexGrow: 1, minHeight: 0, overflow: 'hidden' }}>
              <Box sx={{ height: '100%', overflowY: 'auto', padding: 0 }}>
                <Table
                  columns={routeColumns}
                  dataSource={sortedRoutes}
                  pagination={false}
                  expandable={{
                    expandedRowKeys,
                    onExpand: handleExpand,
                    expandedRowRender: expandedRowRender,
                  }}
                  rowKey={(record) => record.route_id}
                  scroll={{ y: true }}
                  sticky={true}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>

      <Modal
        title="Edit Route Details"
        open={editModalVisible}
        onOk={saveRouteDetails}
        onCancel={() => setEditModalVisible(false)}
      >
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography>Default Subcontractor</Typography>
          <Select
            value={selectedSubcontractor}
            onChange={handleSubcontractorChange}
            options={subcontractors.map((sub) => ({
              label: sub.name,
              value: sub.subcontractor_id,
            }))}
            style={{ width: '100%' }}
            placeholder="Select subcontractor"
          />
          <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
            Only subcontractors assigned to this route may be selected as the default subcontractor.
          </Typography>
        </Box>
      </Modal>
    </ConfigProvider>
  );
};

export default RoutesPage;