// src/pages/InvoicePdfPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, message } from 'antd';
import { fetchInvoicePDF } from '../api';

const InvoicePdfPage = () => {
  const { invoiceId } = useParams();
  const [pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const blob = await fetchInvoicePDF(invoiceId);
        setPdfData(URL.createObjectURL(blob));
      } catch (error) {
        console.error('Failed to load PDF:', error);
        messageApi.error('Failed to load PDF');
      } finally {
        setLoading(false);
      }
    };

    loadPdf();
  }, [invoiceId, messageApi]);

  if (loading) {
    return <Spin>Loading PDF...</Spin>;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      {contextHolder}
      {pdfData ? (
        <object
          data={pdfData}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ margin: 0, padding: 0, border: 'none' }}
        >
          <p>Your browser does not support viewing PDFs. <a href={pdfData}>Download PDF</a>.</p>
        </object>
      ) : (
        <p>PDF could not be loaded.</p>
      )}
    </div>
  );
};

export default InvoicePdfPage;