import React from 'react';
import { Typography, Space } from 'antd';

const { Text } = Typography;

const RenderData = ({ data, level = 0 }) => {
  const capitalizeAndFormat = (str) => {
    return str
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const renderValue = (value, currentLevel) => {

    if (Array.isArray(value)) {
      return (
        <Space direction="vertical" style={{ paddingLeft: '16px', width: '100%' }}>
          {value.map((item, index) => (
            <div key={index} style={{ marginBottom: '4px' }}>
              {typeof item === 'object' ? renderValue(item, currentLevel + 1) : <Text>- {item}</Text>}
            </div>
          ))}
        </Space>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <div style={{ marginBottom: '8px', paddingLeft: '16px' }}>
          {Object.entries(value).map(([nestedKey, nestedValue]) => (
            <div key={nestedKey} style={{ marginBottom: '4px' }}>
              <Text strong>{capitalizeAndFormat(nestedKey)}: </Text>
              {typeof nestedValue === 'object' ? (
                renderValue(nestedValue, currentLevel + 1)
              ) : (
                <Text>{typeof nestedValue === 'boolean' ? (nestedValue ? 'Yes' : 'No') : nestedValue}</Text>
              )}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <Text style={{ paddingLeft: '16px' }}>
          {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
        </Text>
      );
    }
  };

  return (
    <div style={{ paddingTop: '10px' }}>
      {data ? (
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          {Object.entries(data).map(([key, value]) => (
            <div key={key} style={{ marginBottom: '8px', paddingLeft: '16px' }}>
              <Text strong>{capitalizeAndFormat(key)}: </Text>
              {typeof value === 'object' ? (
                renderValue(value, level + 1)
              ) : (
                <Text>{typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}</Text>
              )}
            </div>
          ))}
        </Space>
      ) : (
        <Text>No details available.</Text>
      )}
    </div>
  );
};

export default RenderData;