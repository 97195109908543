import React from 'react';

const Billing = () => {
  return (
    <div>
      <h2>Billing</h2>
      {}
    </div>
  );
};

export default Billing;