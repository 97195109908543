// frontend/src/utils/dateUtils.js
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const toLocalDateString = (utcDateTime) => {
    const cleanUtcString = utcDateTime.trim() + (utcDateTime.endsWith('Z') ? '' : 'Z');
    const utcDate = new Date(cleanUtcString);
    return utcDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
};

export const toLocalTimeString = (utcDateTime) => {
    const cleanUtcString = utcDateTime.trim() + (utcDateTime.endsWith('Z') ? '' : 'Z');
    const utcDate = new Date(cleanUtcString);
    return utcDate.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });
};

export const toLocalDateTime = (utcDateTime) => {
    const hasZ = utcDateTime.endsWith('Z');
    const isoString = hasZ ? utcDateTime : `${utcDateTime}Z`;
    return new Date(isoString);
};

export const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // special rule for 11th to 19th
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
};

export const getFormattedDate = (utcDateTime) => {
    const date = new Date(utcDateTime);

    if (isNaN(date)) {
        return "Date Not Available";
    }

    const day = date.getDate();
    const ordinalSuffix = getOrdinalSuffix(day);

    const weekday = date.toLocaleDateString('en-US', { weekday: 'long' });
    const month = date.toLocaleDateString('en-US', { month: 'long' });
    const year = date.getFullYear();

    return `${weekday}, ${month} ${day}${ordinalSuffix}, ${year}`;
};

export const formatUtcDateTime = (utcDateTime) => {
  return dayjs.utc(utcDateTime).tz(dayjs.tz.guess()).format('YYYY-MM-DD h:mm A');
};