import React from 'react';
import Billing from '../components/Billing';

const BillingPage = () => {
  return (
    <div>
      <h1>Billing Page</h1>
      <Billing />
    </div>
  );
};

export default BillingPage;