// ClientsPage.js
import React, { useState, useEffect } from 'react';
import { Typography, Box, Card, CardContent } from '@mui/material';
import { ConfigProvider, Button, Table, Modal, Input, Form, message } from 'antd';
import { SearchOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { fetchClients, updateClient } from '../api';
import SideMenu from '../components/SideMenu';

const emptyClientData = {
  client_id: '',
  client_name: '',
  contact_first_name: '',
  contact_last_name: '',
  contact_num: '',
  contact_email: '',
  secondary_email: '',
  cc_emails: [],
};

const ClientsPage = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [clientData, setClientData] = useState(emptyClientData);
  const [isFormValid, setIsFormValid] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    loadClients();
  }, []);

  useEffect(() => {
    const filteredData = clients.filter(client => {
      const clientName = client.client_name || 'N/A';
      return clientName.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredClients(filteredData);
  }, [searchQuery, clients]);

  useEffect(() => {
    const validateForm = () => {
      const { client_name, contact_first_name, contact_last_name, contact_num, contact_email, cc_emails } = clientData;
      const isEmailValid = contact_email.includes('@') && contact_email.includes('.');
      const formValid = client_name && contact_first_name && contact_last_name && contact_num && isEmailValid && cc_emails.every(email => email.includes('@') && email.includes('.'));
      setIsFormValid(formValid);
    };

    validateForm();
  }, [clientData]);

  const loadClients = async () => {
    try {
      const data = await fetchClients();
      const sortedData = data.sort((a, b) => {
        const nameA = a.client_name || '';
        const nameB = b.client_name || '';
        return nameA.localeCompare(nameB);
      });
      setClients(sortedData);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const openForm = (client) => {
    if (!client) return;
    setClientData(client);
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setClientData(emptyClientData);
    setIsFormValid(false);
  };

  const handleChange = (field, value) => {
    setClientData(prevData => ({ ...prevData, [field]: value }));
  };

  const addCCEmailRow = () => {
    setClientData(prevData => ({
      ...prevData,
      cc_emails: [...prevData.cc_emails, '']
    }));
  };

  const handleCCEmailChange = (index, value) => {
    const updatedCCEmails = [...clientData.cc_emails];
    updatedCCEmails[index] = value;
    setClientData(prevData => ({
      ...prevData,
      cc_emails: updatedCCEmails
    }));
  };

  const deleteCCEmailRow = (index) => {
    const updatedCCEmails = [...clientData.cc_emails];
    updatedCCEmails.splice(index, 1);
    setClientData(prevData => ({
      ...prevData,
      cc_emails: updatedCCEmails
    }));
  };

  const handleSubmit = async () => {
    try {
      await updateClient(clientData, clientData.client_id);
      messageApi.success('Client updated successfully');
      closeForm();
      loadClients();
    } catch (error) {
      messageApi.error('Failed to update client');
    }
  };

  const renderCCEmailRows = () => {
    return clientData.cc_emails.map((email, index) => (
      <Form.Item key={index} style={{ marginBottom: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Input
            placeholder="CC Email"
            value={email}
            onChange={(e) => handleCCEmailChange(index, e.target.value)}
            style={{ flexGrow: 1 }}
          />
          <DeleteOutlined
            type="danger"
            onClick={() => deleteCCEmailRow(index)}
            style={{ marginLeft: '10px', flexShrink: 0, fontSize: '18px', cursor: 'pointer' }}
          />
        </div>
      </Form.Item>
    ));
  };

  const formatPhoneNumber = (phone) => {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phone;
  };

  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Contact Name',
      key: 'contact_name',
      render: (record) => `${record.contact_first_name} ${record.contact_last_name}`,
    },
    {
      title: 'Contact Email',
      dataIndex: 'contact_email',
      key: 'contact_email',
    },
    {
      title: 'Contact Number',
      dataIndex: 'contact_num',
      key: 'contact_num',
      render: (text) => formatPhoneNumber(text),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: '10%',
      fixed: 'right',
      render: (record) => (
        <EditOutlined
          onClick={() => openForm(record)}
          style={{
            fontSize: '18px',
            cursor: 'pointer',
            color: 'lightgrey',
            transition: 'color 0.3s',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = '#f57e1f')}
          onMouseLeave={(e) => (e.currentTarget.style.color = 'lightgrey')}
        />
      ),
    },
  ];

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#F57D20' } }}>
      {contextHolder}
      <Box display="flex" sx={{ height: '100%' }}>
        {/* Side Menu */}
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>

        {/* Main Content */}
        <Box flexGrow={1} sx={{ display: 'flex', flexDirection: 'column', padding: '16px', overflow: 'hidden' }}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {/* Header Section */}
            <CardContent sx={{ flexShrink: 0, padding: '16px 24px', borderBottom: '1px solid #f0f0f0' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Clients</Typography>
                <Input
                  placeholder="Search Clients"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{ width: '300px' }}
                  prefix={<SearchOutlined style={{ color: '#d9d9d9' }} />}
                />
              </Box>
            </CardContent>

            {/* Table Section */}
            <Box sx={{ flexGrow: 1, minHeight: 0, overflow: 'hidden' }}>
              <Box sx={{ height: '100%', overflowY: 'auto' }}>
                <Table
                  columns={columns}
                  dataSource={filteredClients}
                  pagination={false}
                  scroll={{ y: true }}
                  sticky={true}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>

      {/* Edit Client Modal */}
      <Modal
        open={formOpen}
        onCancel={closeForm}
        title="Edit Client"
        onOk={handleSubmit}
        centered
        okButtonProps={{ disabled: !isFormValid }}
      >
        <Form layout="vertical">
          <Form.Item label="Client Name">
            <Input
              placeholder="Client Name"
              value={clientData.client_name}
              onChange={(e) => handleChange('client_name', e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Contact First Name">
            <Input
              placeholder="Contact First Name"
              value={clientData.contact_first_name}
              onChange={(e) => handleChange('contact_first_name', e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Contact Last Name">
            <Input
              placeholder="Contact Last Name"
              value={clientData.contact_last_name}
              onChange={(e) => handleChange('contact_last_name', e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Contact Number">
            <Input
              placeholder="Contact Number"
              value={clientData.contact_num}
              onChange={(e) => handleChange('contact_num', e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Contact Email">
            <Input
              placeholder="Contact Email"
              value={clientData.contact_email}
              onChange={(e) => handleChange('contact_email', e.target.value)}
            />
          </Form.Item>

          <Form.Item label="CC Emails">
            {renderCCEmailRows()}
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={addCCEmailRow}
              style={{ marginTop: '10px' }}
            >
              Add CC Email
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export default ClientsPage;