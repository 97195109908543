import React, { useState } from 'react';
import { Descriptions, Tag, List, Typography, Divider, Card, Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useConstants } from '../context/ConstantsContext';
import { fetchForecastPdfUrl } from '../api';
import { formatCurrency } from '../utils/stringUtils';
import { formatUtcDateTime } from '../utils/dateUtils';

const PurchaseOrderDetails = ({ purchaseOrder }) => {
  const constants = useConstants();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const statusColour = (status) => {
    if (status === constants.status.pending) return 'red';
    if (status === constants.status.awaiting) return '#DAA520';
    if (status === constants.status.approved) return 'green';
  };

  if (!purchaseOrder) {
    return <Typography>No purchase order data available</Typography>;
  }

  const onDownloadForecastPdf = async (forecastId) => {
    setLoadingPdf(true);
    try {
      const response = await fetchForecastPdfUrl(forecastId);
      const pdfUrl = response.pdf_url;
      if (pdfUrl) {
        window.open(pdfUrl, '_blank');
      } else {
        messageApi.error('PDF URL not available.');
      }
    } catch (error) {
      console.error('Error fetching forecast PDF:', error);
      messageApi.error('Failed to fetch the forecast PDF.');
    } finally {
      setLoadingPdf(false);
    }
  };

  const renderServiceHistories = () => {
    if (!purchaseOrder.service_histories || purchaseOrder.service_histories.length === 0) {
      return <Typography>No service histories available</Typography>;
    }

    return (
      <List
        itemLayout="horizontal"
        bordered
        dataSource={purchaseOrder.service_histories}
        renderItem={(history) => (
          <Card>
            <Descriptions title={`Service History ID: ${history.id}`} bordered column={1} size="small" labelStyle={{ width: '150px' }}>
              <Descriptions.Item label="Event Name">{history.event_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Route Name">{history.route_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Subcontractor Name">{history.subcontractor_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Site Name">{history.site_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Client Name">{history.client_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Services">{history.services || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Started At">{formatUtcDateTime(history.started_at)}</Descriptions.Item>
              <Descriptions.Item label="Ended At">{formatUtcDateTime(history.ended_at)}</Descriptions.Item>
              <Descriptions.Item label="Duration">{history.duration ? `${history.duration} hours` : 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Equipment Used">
                {history.equipment && history.equipment.length > 0 ? (
                  <List
                    dataSource={history.equipment}
                    renderItem={(equipment) => (
                      <List.Item>
                        <Typography>{equipment.name} - {equipment.calculated_rate ? `${equipment.calculated_rate} $/hr` : 'Rate Unavailable'}</Typography>
                      </List.Item>
                    )}
                    bordered={false}
                    size="small"
                  />
                ) : (
                  <Typography>N/A</Typography>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        )}
      />
    );
  };

  return (
    <>
      {contextHolder}

      {/* Purchase Order Details */}
      <Descriptions title="Purchase Order Details" bordered column={1} labelStyle={{ width: '150px' }}>
        <Descriptions.Item label="PO ID">{purchaseOrder.po_id}</Descriptions.Item>
        <Descriptions.Item label="Contract Type">{purchaseOrder.contract_type || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Total Duration">{purchaseOrder.total_duration.toFixed(3)} hours</Descriptions.Item>
        { purchaseOrder.country === constants.country.canada &&
          <>
            <Descriptions.Item label="Net Total">{formatCurrency(purchaseOrder.total_amount_sum, purchaseOrder.country, constants)}</Descriptions.Item>
            <Descriptions.Item label={`GST ${constants.taxes.cad_tax_rate * 100}%`}>{formatCurrency(purchaseOrder.total_amount_sum * constants.taxes.cad_tax_rate, purchaseOrder.country, constants)}</Descriptions.Item>
            <Descriptions.Item label="Gross Total">{formatCurrency(purchaseOrder.total_amount_sum * (1 + constants.taxes.cad_tax_rate), purchaseOrder.country, constants)}</Descriptions.Item>
          </>
        }
        { purchaseOrder.country === constants.country.usa &&
          <Descriptions.Item label="Gross Total">{formatCurrency(purchaseOrder.total_amount_sum, purchaseOrder.country, constants)}</Descriptions.Item>
        }
        <Descriptions.Item label="Status">
          <Tag color={statusColour(purchaseOrder.status)}>{purchaseOrder.status}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Created By">{purchaseOrder.created_by || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Created At">{formatUtcDateTime(purchaseOrder.created_at)}</Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* Event & Subcontractor Information */}
      <Descriptions title="Subcontractor & Event Information" bordered column={1} labelStyle={{ width: '150px' }}>
        <Descriptions.Item label="Subcontractor Name">{purchaseOrder.subcontractor_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Site Name">{purchaseOrder.site_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Route Name">{purchaseOrder.route_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Zone">{purchaseOrder.zone_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Event Name">{purchaseOrder.event_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Event Type">{purchaseOrder.event_type || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Service Type">{purchaseOrder.service_type || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Event Date">{purchaseOrder.event_date || 'N/A'}</Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* Weather Details */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
        <Typography.Title level={5} style={{ margin: 0 }}>Weather Details</Typography.Title>
        <Button
          type="secondary"
          icon={<DownloadOutlined />}
          loading={loadingPdf}
          onClick={() => onDownloadForecastPdf(purchaseOrder.forecast.forecast_id)}
        >
          Download PDF
        </Button>
      </div>
      <Descriptions bordered column={1} labelStyle={{ width: '150px' }}>
        <Descriptions.Item label="Station Name">{purchaseOrder.forecast.station_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Country">{purchaseOrder.forecast.country || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Forecast Date">{purchaseOrder.forecast.date || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Forecast">{purchaseOrder.forecast.forecast || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Low Temp">{purchaseOrder.forecast.low_temp}°C</Descriptions.Item>
        <Descriptions.Item label="High Temp">{purchaseOrder.forecast.high_temp}°C</Descriptions.Item>
        <Descriptions.Item label="Forecasted Accumulation">{purchaseOrder.forecast.forecast_accum} cm</Descriptions.Item>
        <Descriptions.Item label="Actual Accumulation">
          {purchaseOrder.forecast.actual_accum != null ? (
            `${purchaseOrder.forecast.actual_accum} cm`
          ) : (
            <Tag color="red">Weather Data Incomplete</Tag>
          )}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* Service Histories */}
      {renderServiceHistories()}
    </>
  );
};

export default PurchaseOrderDetails;