// WeatherHistoryPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { Table, Button, ConfigProvider, Select, message, Card, Tag, Input } from 'antd';
import SideMenu from '../components/SideMenu';
import {
  fetchEvents,
  fetchEventForecasts,
  updateForecastAccumulation
} from '../api';
import { useCurrentEvent } from '../context/EventContext';
import { useConstants } from '../context/ConstantsContext';
import '../styles/ButtonStyles.css'
import '../styles/CustomCheckbox.css'
import { DownloadOutlined, CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';

const EventHistoryPage = () => {
  const constants = useConstants();
  const { currentEvent, setCurrentEvent } = useCurrentEvent();
  const [events, setEvents] = useState([]);
  const [forecasts, setForecasts] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editingKey, setEditingKey] = useState(null);
  const [editingValue, setEditingValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const loadEvents = useCallback(async () => {
    const events = await fetchEvents({ active: 'false', order: 'desc' });
    setEvents(events)
  }, []);

  const loadForecasts = async (eventId) => {
    setLoading(true);
    const forecast_data = await fetchEventForecasts(eventId);
    console.log(forecast_data);
    setForecasts(forecast_data);
    setLoading(false);
  }

  useEffect(() => {
    loadEvents();
    setSelectedEvent(currentEvent);
    if (currentEvent) {
      loadForecasts(currentEvent.event_id);
    }
  }, [loadEvents, currentEvent]);

  const handleEventChange = async (eventId) => {
    try {
      const selectedEventObj = events.find(event => event.event_id === eventId);
      if (selectedEventObj) {
        setCurrentEvent(eventId);
        loadForecasts(eventId);
      }
    } catch (error) {
      messageApi.error('Error fetching event details');
      console.error(error);
    }
  };

  const editAccumulation = (record) => {
    setEditingKey(record.weather_station_name);
    setEditingValue(record.actual_accum);
  };

  const cancelEditAccumulation = () => {
    setEditingKey(null);
    setEditingValue(null);
  };

  const saveEditAccumulation = async (record) => {
    try {
      await updateForecastAccumulation(record.forecast_id, editingValue);
      const updatedForecasts = forecasts.map((item) =>
        item.forecast_id === record.forecast_id
          ? { ...item, actual_accum: editingValue }
          : item
      );
      setForecasts(updatedForecasts);
      messageApi.success('Forecast actual accumulation updated successfully');
      cancelEditAccumulation();
    } catch (error) {
      messageApi.error('Failed to update forecast actual accumulation');
      cancelEditAccumulation();
    }
  };

  const forecastColumns = [
    {
      title: 'Weather Station Name',
      dataIndex: 'weather_station_name',
      key: 'weather_station_name',
      width: '30%',
    },
    {
      title: 'Forecast',
      dataIndex: 'forecast',
      key: 'forecast',
      width: '15%',
    },
    {
      title: 'Forecast Low',
      dataIndex: 'low_temp',
      key: 'low_temp',
      width: '10%',
      render: (low_temp, record) => (
        <span>
          {low_temp}°{record.country === constants.country.canada ? 'C' : 'F'}
        </span>
      ),
    },
    {
      title: 'Forecast High',
      dataIndex: 'high_temp',
      key: 'high_temp',
      width: '10%',
      render: (high_temp, record) => (
        <span>
          {high_temp}°{record.country === constants.country.canada ? 'C' : 'F'}
        </span>
      ),
    },
    {
      title: 'Forecast Accum',
      dataIndex: 'forecast_accum',
      key: 'forecast_accum',
      width: '10%',
      render: (forecast_accum, record) => (
        <span>
          {forecast_accum} {record.country === constants.country.canada ? 'cm' : 'inches'}
        </span>
      ),
    },
    {
      title: 'Actual Accum',
      dataIndex: 'actual_accum',
      key: 'actual_accum',
      width: '15%',
      render: (actual_accum, record) =>
        editingKey === record.weather_station_name ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              style={{ width: '60%', marginRight: '8px' }}
              autoFocus
            />
            <CheckOutlined
              onClick={() => saveEditAccumulation(record)}
              style={{ color: 'green', cursor: 'pointer', marginRight: '8px' }}
            />
            <CloseOutlined
              onClick={cancelEditAccumulation}
              style={{ color: 'red', cursor: 'pointer' }}
            />
          </div>
        ) : actual_accum !== null ? (
          <span
            onClick={() => editAccumulation(record)}
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            {actual_accum} {record.country === constants.country.canada ? 'cm' : 'inches'}
            <EditOutlined style={{ fontSize: '0.8em', color: 'gray' }} />
          </span>
        ) : (
          <Tag color="red" onClick={() => editAccumulation(record)}>
            Weather Data Incomplete
          </Tag>
        ),
    },
    {
      title: 'Forecast PDF',
      dataIndex: 'pdf_url',
      key: 'pdf_url',
      width: '10%',
      align: 'right',
      render: (pdf_url) => (
        <Button
          type="secondary"
          shape="circle"
          icon={<DownloadOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            window.open(pdf_url, '_blank');
          }}
        />
      ),
    },
  ]

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#F57D20', colorLinkHover: '#F57D20', colorLink: '#F57D20' } }}>
      {contextHolder}
      <Box display="flex">
        {/* Side Menu */}
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        
        {/* Main Content */}
        <Box sx={{ flex: 1, display: 'flex', paddingRight: '16px' }}>
          <Card
            style={{
              height: 'calc(100vh - 72px - 16px)',
              flex: 1,
              overflow: 'hidden',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
            }}
          >

            {/* Header and Event Selection */}
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <Typography variant="h5" component="div">
                Weather History
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Select
                  placeholder="Select Event"
                  style={{ width: 320 }}
                  options={events.map((event) => ({
                    label: `${event.event_name} - ${event.service_type}`,
                    value: event.event_id,
                  }))}
                  value={selectedEvent?.event_id || null}
                  onChange={handleEventChange}
                />
              </Box>
            </Box>

            <Box sx={{ overflow: 'hidden', marginTop: '16px' }}>
              <Table
                loading={loading}
                dataSource={forecasts}
                columns={forecastColumns}
                rowKey="weather_station_name"
                pagination={false}
                locale={{
                  emptyText: 'No weather stations',
                }}
              />
            </Box>
          </Card>
        </Box>
      </Box>
    </ConfigProvider>
  );
};

export default EventHistoryPage;
