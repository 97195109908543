// frontend/src/components/InvoiceAndPurchaseOrderComponents.js
import React from 'react';
import { Tag, Popover, Radio, Collapse, Descriptions, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useConstants } from '../context/ConstantsContext';
import { getFormattedDate } from '../utils/dateUtils';
import { formatCurrency } from '../utils/stringUtils';

const { Panel } = Collapse;


const StatusEditor = ({ status, record, statusUpdateFunction, statusType, handleStatusUpdate, messageApi }) => {
  const constants = useConstants();

  const onStatusChange = async (newStatus) => {
    try {
      await statusUpdateFunction(record.key, newStatus);
      handleStatusUpdate(record.key, newStatus);
      messageApi.success(`${statusType} Status Updated`);
    } catch (error) {
      messageApi.error(`Failed to update ${statusType} status`);
    }
  };

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ cursor: 'pointer', marginBottom: '8px' }} onClick={() => onStatusChange(constants.status.pending)}>
        <Radio checked={status === constants.status.pending} /> Pending
      </div>
      <div style={{ cursor: 'pointer' }} onClick={() => onStatusChange(constants.status.awaiting)}>
        <Radio checked={status === constants.status.awaiting} /> Awaiting Approval
      </div>
    </div>
  );

  const colorMap = {
    [constants.status.approved]: 'green',
    [constants.status.awaiting]: '#DAA520',
    [constants.status.pending]: 'red',
  };

  return (
    record.status === constants.status.approved ? (
      <Tag color={colorMap[status]}> Approved </Tag>
    ) : (
      <Popover
        content={content}
        trigger="click"
        overlayStyle={{ zIndex: 9999 }}
        onClick={(e) => e.stopPropagation()}
      >
        <Tag color={colorMap[status]} style={{ cursor: 'pointer' }}>{status}</Tag>
      </Popover>
    )
  );
};


const PurchaseOrderList = ({ purchaseOrders, batchMessages, batchLoadingStates, constants }) => {
  return (
    <Collapse accordion>
      {purchaseOrders.map((po) => (
        <Panel
          header={
            batchMessages[po.po_id] ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ fontWeight: 'bold' }}>PO-{po.po_id}</span>
                <span style={{ color: batchMessages[po.po_id].includes('Failed') ? 'red' : 'green' }}>
                {batchMessages[po.po_id]}
                </span>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ width: '10%', fontWeight: 'bold' }}>PO-{po.po_id}</span>
                <span style={{ width: '15%', textAlign: 'left' }}>{formatCurrency(po.total_amount_sum, po.country, constants)}</span>
                <span style={{ width: '25%', textAlign: 'left' }}>{po.subcontractor_name || 'Subcontractor Not Defined'}</span>
                <span style={{ width: '25%', textAlign: 'left' }}>{po.route_name || 'Route Not Defined'}</span>
                {po.event_type === constants.event_type.snow && <span style={{ width: '25%', textAlign: 'left' }}>{po.site_name || 'Site Unavailable'}</span>}
                {batchLoadingStates[po.po_id] && (
                  <Spin
                    indicator={<LoadingOutlined spin />}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  />
                )}
              </div>
            )
          }
          key={po.po_id}
        >
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="Event Name">{po.event_name}</Descriptions.Item>
            <Descriptions.Item label="Event Date">{getFormattedDate(po.event_date)}</Descriptions.Item>
            <Descriptions.Item label="Subcontractor">{po.subcontractor_name}</Descriptions.Item>
            <Descriptions.Item label="Site">{po.site_name || 'Site Not Defined'}</Descriptions.Item>
            <Descriptions.Item label="Route">{po.route_name || 'Not Defined'}</Descriptions.Item>
            <Descriptions.Item label="Service Type">{po.service_type}</Descriptions.Item>
            <Descriptions.Item label="Contract Type">{po.contract_type || 'Contract Type Not Defined'}</Descriptions.Item>
            { po.country === constants.country.canada &&
              <>
                <Descriptions.Item label="Net Total">{formatCurrency(po.total_amount_sum, po.country, constants)}</Descriptions.Item>
                <Descriptions.Item label={`GST ${constants.taxes.cad_tax_rate * 100}%`}>{formatCurrency(po.total_amount_sum * constants.taxes.cad_tax_rate, po.country, constants)}</Descriptions.Item>
                <Descriptions.Item label="Gross Total">{formatCurrency(po.total_amount_sum * (1 + constants.taxes.cad_tax_rate), po.country, constants)}</Descriptions.Item>
              </>
            }
            { po.country === constants.country.usa &&
              <Descriptions.Item label="Gross Total">{formatCurrency(po.total_amount_sum, po.country, constants)}</Descriptions.Item>
            }
            <Descriptions.Item label="Status">{po.status}</Descriptions.Item>
          </Descriptions>
        </Panel>
      ))}
    </Collapse>
  );
};


const InvoiceList = ({ invoices, batchMessages, batchLoadingStates }) => {
  return (
    <Collapse accordion>
      {invoices.map((invoice) => (
        <Panel
          header={
            batchMessages[invoice.invoice_id] ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ fontWeight: 'bold' }}>Invoice-{invoice.invoice_id}</span>
                <span style={{ color: batchMessages[invoice.invoice_id].includes('Failed') ? 'red' : 'green' }}>
                {batchMessages[invoice.invoice_id]}
                </span>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ width: '15%', fontWeight: 'bold' }}>Invoice-{invoice.invoice_id}</span>
                {/* <span style={{ width: '20%', textAlign: 'left' }}>{invoice.total_amount_sum ? `$${invoice.total_amount_sum.toFixed(2)}` : 'N/A'}</span> */}
                <span style={{ width: '30%', textAlign: 'left' }}>{invoice.client_name || 'Client Not Defined'}</span>
                <span style={{ width: '30%', textAlign: 'left' }}>{invoice.site_name || 'Site Not Defined'}</span>
                {batchLoadingStates[invoice.invoice_id] && (
                  <Spin
                    indicator={<LoadingOutlined spin />}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  />
                )}
              </div>
            )
          }
          key={invoice.invoice_id}
        >
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="Event Name">{invoice.event_name}</Descriptions.Item>
            <Descriptions.Item label="Event Date">{getFormattedDate(invoice.event_date)}</Descriptions.Item>
            <Descriptions.Item label="Client">{invoice.client_nam || 'Client Not Defined'}</Descriptions.Item>
            <Descriptions.Item label="Site">{invoice.site_name || 'Site Not Defined'}</Descriptions.Item>
            <Descriptions.Item label="Service Type">{invoice.service_type}</Descriptions.Item>
            <Descriptions.Item label="Contract Type">{invoice.contract_type || 'Contract Type Not Defined'}</Descriptions.Item>
            {/* <Descriptions.Item label="Total Amount">{invoice.total_amount_sum ? `$${invoice.total_amount_sum.toFixed(2)}` : 'N/A'}</Descriptions.Item> */}
            <Descriptions.Item label="Status">{invoice.status}</Descriptions.Item>
          </Descriptions>
        </Panel>
      ))}
    </Collapse>
  );
};

export { StatusEditor, PurchaseOrderList, InvoiceList };