// src/pages/RegistrationPage.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { registerUser } from '../api';
import '../styles/RegisterPage.css';
import { motion } from 'framer-motion';
import { Input, Button, ConfigProvider } from 'antd';
import { KeyOutlined, UserOutlined } from '@ant-design/icons';
import PhoneInput from "antd-phone-input";
import FormItem from 'antd/es/form/FormItem';

const RegistrationPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const [phoneError, setPhoneError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (password !== confirmPassword) {
      setNotificationMessage('Passwords do not match');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }
    
    if (phoneNumber.length !== 10) {
      setPhoneError(true);
      setNotificationMessage('Please enter a valid phone number +1 (123-456-7890)');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    try {
      await registerUser({
        token,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        phoneNumber: phoneNumber,
        password
      });
      setNotificationMessage('Registration successful');
      setNotificationSeverity('success');
      setNotificationOpen(true);
      navigate('/login');
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Registration failed';
      setNotificationMessage(errorMsg);
      setNotificationSeverity('error');
      setNotificationOpen(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F57D20',
        },
      }}
    >
    <div className="container">
      <motion.div 
        className="main"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          type: "spring",
          stiffness: 150,
          damping: 30,
        }}
      >
        <div className="textStack">
          <span className="title">Get Started!</span>
          <span className="subtitle">
            Please enter your details to create an account.
          </span>
        </div>
        <div className="fieldStack">
          <div className="twoBox">
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)', marginRight: '5px' }} />}
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value.trim())}
              onKeyPress={handleKeyPress}
              className='textField'
            />
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)', marginRight: '5px' }} />}
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value.trim())}
              onKeyPress={handleKeyPress}
              className='textField'
            />
          </div>
          <FormItem name="phone" style={{ width: '100%', marginBottom: '0px' }}>
            <PhoneInput
              disableDropdown
              onlyCountries={['ca']}
              value={{ countryCode: '', areaCode: '', phoneNumber: '', isoCode: '', valid: () => false }} 
              placeholder='Phone Number'
              onChange={(value) => setPhoneNumber(value.areaCode + value.phoneNumber)}
              initialValue={{ countryCode: '', areaCode: '', phoneNumber: '', isoCode: '', valid: () => false }}
              onMount={(value) => setPhoneNumber('')}
              onKeyPress={handleKeyPress} 
            />
          </FormItem>
          <Input.Password
            prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)', marginRight: '5px' }} />}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            className='textField'
          />
          <Input.Password
            prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)', marginRight: '5px' }} />}
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            className='textField'
          />
        </div>
        <Button type="primary" onClick={handleSubmit} className="submitButton" block>Sign Up</Button>
      </motion.div>
      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={() => setNotificationOpen(false)}
      >
        <Alert onClose={() => setNotificationOpen(false)} severity={notificationSeverity}>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </div>
    </ConfigProvider>
  );
};

export default RegistrationPage;
