// NotifyPage.js
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ConfigProvider, Select, Table, Flex, Modal, Input, Checkbox, Card, message, notification } from 'antd';
import { PlusOutlined, DeleteOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons'; 
import { Typography, Box } from '@mui/material';
import {
  fetchEvents, 
  fetchEventRouteSites, 
  fetchSites, 
  fetchNotifiableEventRoutes, 
  addEventRouteSites, 
  removeEventRouteSite, 
  createEventRouteNotifications 
} from '../api';
import SideMenu from '../components/SideMenu';
import WeatherData from '../components/WeatherData';
import { useUser } from '../context/UserContext';
import { useCurrentEvent } from '../context/EventContext';

const NotifyPage = () => {
  const { user } = useUser();
  const { currentEvent, setCurrentEvent } = useCurrentEvent();
  const [messageApi, contextHolder] = message.useMessage();

  const [activeEvents, setActiveEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [eventRoutes, setEventRoutes] = useState([]);
  const [sites, setSites] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [allSelectedKeys, setAllSelectedKeys] = useState(new Set());
  const [filteredRowKeys, setFilteredRowKeys] = useState([]);

  const [skipClientNotifications, setSkipClientNotifications] = useState(false);
  const [isConfirmNotifyOpen, setIsConfirmNotifyOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);


  const navigate = useNavigate();

  // Fetch active events and set the selected event
  useEffect(() => {
    const loadActiveEvents = async () => {
      try {
        const events = await fetchEvents({ active: 'true', order: 'desc' });
        const eventOptions = events.map(event => ({
          label: `${event.event_name} - ${event.service_type}`,
          value: event.event_id,
        }));
        setActiveEvents(eventOptions);
        if (currentEvent && currentEvent.event_id) {
          setSelectedEventId(currentEvent.event_id);
        } else if (eventOptions.length > 0) {
          await setCurrentEvent(eventOptions[0].value);
          setSelectedEventId(eventOptions[0].value);
        }
      } catch (error) {
        console.error('Error fetching active events:', error);
      }
    };

    loadActiveEvents();
  }, [currentEvent, setCurrentEvent]);

  const loadEventRoutes = useCallback(async () => {
    try {
      const eventRoutes = await fetchNotifiableEventRoutes(selectedEventId);
      setEventRoutes(eventRoutes);
    } catch (error) {
      console.error('Error fetching event routes:', error);
    }
  }, [selectedEventId]);

  useEffect(() => {
    if (selectedEventId) {
      loadEventRoutes();
    }
  }, [selectedEventId, loadEventRoutes]);

  useEffect(() => {
    const fetchAllSites = async () => {
      try {
        const response = await fetchSites();
        setSites(response);
      } catch (error) {
        console.error('Error fetching all sites:', error);
      }
    };
    fetchAllSites();
  }, []);

  const extractZoneName = (zoneName) => zoneName.split('R')[0];

  const uniqueZones = useMemo(() => {
    return Array.from(new Set(eventRoutes.map((route) => extractZoneName(route.zone_name))));
  }, [eventRoutes]);

  const handleZoneChange = (value) => setSelectedZone(value);

  const handleEventChange = async (value) => {
    await setCurrentEvent(value); // set global event context
    setSelectedEventId(value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEventRoutes = useMemo(() => {
    return eventRoutes
      .filter((eventRoute) => selectedZone === '' || extractZoneName(eventRoute.zone_name) === selectedZone)
      .filter((eventRoute) => eventRoute.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [eventRoutes, selectedZone, searchQuery]);

  const navigateDispatchPage = () => {
    navigate('/dispatch');
  };

  useEffect(() => {
    const currentFilteredKeys = filteredEventRoutes.map(route => route.key);
    setFilteredRowKeys([...allSelectedKeys].filter(key => currentFilteredKeys.includes(key)));
  }, [filteredEventRoutes, allSelectedKeys]);

  const onSelectChange = (newSelectedKeys) => {
    const updatedSelectedKeys = new Set(allSelectedKeys);
    newSelectedKeys.forEach(key => updatedSelectedKeys.add(key));
    filteredRowKeys.forEach(key => {
      if (!newSelectedKeys.includes(key)) updatedSelectedKeys.delete(key);
    });
    setAllSelectedKeys(updatedSelectedKeys);
    setFilteredRowKeys(newSelectedKeys);
  };

  const rowSelection = {
    selectedRowKeys: filteredRowKeys,
    onChange: onSelectChange,
  };

  const handleConfirmNotify = async () => {
    setConfirmLoading(true);
    try {
      const response = await createEventRouteNotifications(selectedEventId, {
        routes: [...allSelectedKeys],
        user_email: user.email,
        skip_notifications: skipClientNotifications,
      });
      console.log(response);
      const { status, successes, errors } = response;
      if (status === 'success') {
        const message = !skipClientNotifications ? 'Clients notified.' : 'Routes moved to dispatch.';
        messageApi.success(message);
      } else if (status === 'partial success') {
        notification.warning({
          message: 'Partial Success',
          description: (
            <>
              <p>Some notifications were sent successfully, but there were errors:</p>
              <ul style={{ paddingLeft: '15px' }}>
                {errors.map(([routeName, errorMessages], index) => (
                  <li key={index} style={{ marginBottom: '8px' }}>
                    {routeName}
                    <ul style={{ paddingLeft: '15px', marginTop: '4px' }}>
                      {errorMessages.map((error, subIndex) => (
                        <li key={subIndex} style={{ paddingLeft: '5px' }}>{error}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      } else if (status === 'failure') {
        notification.error({
          message: 'Notification Error',
          description: (
            <>
              <p>Unable to notify clients for the selected routes:</p>
              <ul style={{ paddingLeft: '15px' }}>
                {errors.map(([routeName, errorMessages], index) => (
                  <li key={index} style={{ marginBottom: '8px' }}>
                    {routeName}
                    <ul style={{ paddingLeft: '15px', marginTop: '4px' }}>
                      {errorMessages.map((error, subIndex) => (
                        <li key={subIndex} style={{ paddingLeft: '5px' }}>{error}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      }
      
      await loadEventRoutes();
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Notification Error',
        description: 'An unexpected error occurred while notifying clients.',
      });
    } finally {
      setFilteredRowKeys([]);
      setAllSelectedKeys(new Set());
      setConfirmLoading(false);
      setIsConfirmNotifyOpen(false);
      setSkipClientNotifications(false);
    }
  };

  const handleCancelNotify = () => {
    setIsConfirmNotifyOpen(false);
  };

  const openConfirmNotify = () => {
    setIsConfirmNotifyOpen(true);
  };

  const columns = [
    {
      title: 'Route',
      dataIndex: 'name',
      key: 'route',
      width: '50%',
      ellipsis: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Zone',
      dataIndex: 'zone_name',
      key: 'zone',
      align: 'center',
      ellipsis: true,
      sorter: (a, b) => a.zone_name.localeCompare(b.zone_name),
    },
    // {
    //   title: 'Temperature',
    //   dataIndex: 'temperature',
    //   key: 'temperature',
    //   align: 'center',
    //   ellipsis: true,
    //   sorter: (a, b) => a.temperature - b.temperature,
    // }
  ];

  const [tableHeight, setTableHeight] = useState('100vh');
  const titleBoxRef = useRef(null);
  const actionBoxRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const calculateTableHeight = () => {
      const headerHeight = 72;
      const titleBoxHeight = titleBoxRef.current ? titleBoxRef.current.offsetHeight : 32;
      const actionBoxHeight = actionBoxRef.current ? actionBoxRef.current.offsetHeight : 32;
      const padding = 16;

      // Calculate height of the table header
      const tableHeaderHeight = tableRef.current ? tableRef.current.querySelector('.ant-table-thead').offsetHeight : 48;

      const totalHeightAroundTableRows =
        headerHeight +
        titleBoxHeight +
        actionBoxHeight +
        padding * 6 +
        tableHeaderHeight;

      setTableHeight(`calc(100vh - ${totalHeightAroundTableRows}px)`);
    };

    if (selectedEventId) {
        calculateTableHeight();
    }

    window.addEventListener('resize', calculateTableHeight);
    return () => window.removeEventListener('resize', calculateTableHeight);
  }, [selectedEventId]);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#F57D20', colorLinkHover: '#F57D20', colorLink: '#F57D20' } }}>
      {contextHolder}
      <Box display="flex">
        {/* Side Menu */}
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        
        {/* Main Content */}
        <Card
          style={{
            height: 'calc(100vh - 72px - 16px)',
            marginRight: '16px',
            overflow: 'hidden',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
          }}
        >

          {/* Header and Event Selection */}
          <Box ref={titleBoxRef} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <Typography variant="h5" component="div">
              Notify Routes
            </Typography>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Select
                value={selectedEventId}
                onChange={handleEventChange}
                style={{ width: 320 }}
                placeholder="Select an Event"
                options={activeEvents}
              />
            </Box>
          </Box>

          {/* Search, Filters, and Action Buttons */}
          <Box ref={actionBoxRef} display="flex" justifyContent="space-between" alignItems="center" marginTop="16px">
            <Box display="flex" alignItems="center" gap={2}>
              {/* Search Box */}
              <Input
                placeholder="Search Routes"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ width: '300px' }}
                prefix={<SearchOutlined style={{ color: '#d9d9d9' }} />}
              />

              {/* Zone Selector */}
              <Select
                value={selectedZone}
                onChange={handleZoneChange}
                displayEmpty
                style={{ minWidth: '100px' }}
              >
                <option value="">All Zones</option>
                {uniqueZones.map((zone) => (
                  <option key={zone} value={zone}>
                    {zone}
                  </option>
                ))}
              </Select>
            </Box>

            <Box display="flex" alignItems="center" gap={1}>
              {/* Skip Client Notifications Checkbox */}
              <Checkbox
                checked={skipClientNotifications}
                onChange={(e) => setSkipClientNotifications(e.target.checked)}
              >
                Skip Client Notifications
              </Checkbox>

              {/* Notify Clients Button */}
              <Button
                type="primary"
                onClick={openConfirmNotify}
                disabled={allSelectedKeys.size < 1}
                style={{ minWidth: '200px' }}
              >
                {skipClientNotifications ? "Move Routes to Dispatch" : "Notify Clients"}
              </Button>
            </Box>
          </Box>

          {/* Scrollable Table */}
          <Box ref={tableRef} sx={{ overflow: 'hidden', marginTop: '16px' }}>
            <Table
              columns={columns}
              dataSource={filteredEventRoutes}
              rowSelection={rowSelection}
              pagination={false}
              scroll={{ y: tableHeight }}
              sticky
              expandable={{
                expandedRowRender: (eventRoute) => (
                  <RouteRow
                    event_id={selectedEventId}
                    route_id={eventRoute.id}
                    sites={sites}
                  />
                ),
              }}
            />
          </Box>
        </Card>
      </Box>

      <ConfirmNotifyModal
        isOpen={isConfirmNotifyOpen}
        handleConfirm={handleConfirmNotify}
        handleCancel={handleCancelNotify}
        confirmLoading={confirmLoading}
        skipClientNotifications={skipClientNotifications}
      />
    </ConfigProvider>
  );
};

const RouteRow = ({ event_id, route_id, sites }) => {
  const [routeSites, setRouteSites] = useState([]);
  const [availableSites, setAvailableSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [confirmingSiteRemoval, setConfirmingSiteRemoval] = useState(null);
  const [confirmingSiteElement, setConfirmingSiteElement] = useState(null);

  // Load the sites assigned to this route
  const loadRouteSites = useCallback(async () => {
    try {
      const data = await fetchEventRouteSites(route_id);
      console.log('route sites:', data);
      setRouteSites(data);
    } catch (error) {
      console.error('Error fetching route sites:', error);
    }
  }, [route_id]);

  // Filter available sites for this route, ensuring we wait for `routeSites`
  useEffect(() => {
    if (routeSites.length > 0 || sites.length > 0) {
      const filteredSites = sites.filter(
        site => !routeSites.some(assignedSite => assignedSite.site_id === site.site_id)
      );
      const siteOptions = filteredSites.map(site => ({
        label: site.site_name,
        value: site.site_id
      }));
      setAvailableSites(siteOptions);
    }
  }, [routeSites, sites]);

  // Handle adding a new site
  const handleAddSelectedSite = async () => {
    try {
      await addEventRouteSites(event_id, route_id, { sites: [selectedSite] });
      await loadRouteSites();
      setSelectedSite(null);
    } catch (error) {
      console.error('Error adding site:', error);
    }
  };

  // Update the handleRemoveSite function to include the event parameter
  const handleRemoveSite = useCallback(async (siteToRemove, event) => {
    if (confirmingSiteRemoval === siteToRemove.id) {
      await removeEventRouteSite(event_id, route_id, siteToRemove.id);
      await loadRouteSites();
      setConfirmingSiteRemoval(null);
      setConfirmingSiteElement(null);
    } else {
      setConfirmingSiteRemoval(siteToRemove.id);
      setConfirmingSiteElement(event.currentTarget); // Set the clicked button as the confirming element
    }
  }, [confirmingSiteRemoval, event_id, loadRouteSites, route_id]);

  // Load route sites when the component mounts or when the route_id changes
  useEffect(() => {
    loadRouteSites();
  }, [loadRouteSites]);

  // Detect outside click to reset the delete button
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (confirmingSiteElement && !confirmingSiteElement.contains(event.target)) {
        setConfirmingSiteRemoval(null);
        setConfirmingSiteElement(null);
      }
    };

    if (confirmingSiteElement) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [confirmingSiteElement]);

  const siteColumns = useMemo(() => {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        width: '130px',
        align: 'center',
        render: (_, record) => {
          const isConfirming = confirmingSiteRemoval === record.id;
          return (
            <Button
              shape="round"
              type={isConfirming ? 'primary' : 'text'}
              danger={isConfirming}
              icon={isConfirming ? null : <DeleteOutlined />}
              onClick={(event) => handleRemoveSite(record, event)}
            >
              {isConfirming ? 'Remove Site' : ''}
            </Button>
          );
        },
      },
    ];

    return columns;
  }, [confirmingSiteRemoval, handleRemoveSite]);

  return (
    <Flex vertical gap='large'>
      <Flex vertical={false} justify='space-between' align='center' gap='large'>
        <em>Add sites to the service notification</em>
        <Flex vertical={false} justify='flex-end' gap='small'>
          <Select
            value={selectedSite}
            showSearch
            placeholder="Search sites"
            options={availableSites}
            style={{ width: 400 }}
            onChange={(value) => setSelectedSite(value)}
            optionFilterProp='label'
          />
          <Button disabled={selectedSite === null} icon={<PlusOutlined />} iconPosition='end' onClick={handleAddSelectedSite}>
            Add Site
          </Button>
        </Flex>
      </Flex>
      <Table
        pagination={false}
        columns={siteColumns}
        dataSource={routeSites}
        size='small'
        showHeader={false}
        locale={{
          emptyText: 'No sites have been selected for this route',
        }}
      />
    </Flex>
  );
};

const ConfirmNotifyModal = ({ isOpen, handleConfirm, handleCancel, confirmLoading, skipClientNotifications }) => {
  return (
    <Modal
      title={skipClientNotifications ? "Confirm Dispatch Without Notification" : "Confirm Client Notification"}
      open={isOpen}
      onOk={handleConfirm}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
    >
      {skipClientNotifications ? (
        <p><strong>Skipping Client Notification</strong><br />Are you sure you want to move these routes to dispatch without notifying clients of service?</p>
      ) : (
        <p>Are you sure you want to notify for the selected routes and sites? This will send emails to the associated clients and make the selected routes available for dispatch.</p>
      )}
    </Modal>
  );
};

export default NotifyPage;
