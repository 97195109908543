// src/pages/ProfilePage.js
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Snackbar, Alert, MenuItem, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import SideMenu from '../components/SideMenu';
import { sendInvite, getUsers, updateUserRole, deleteUser, updateProfile } from '../api';
import { formatPhoneNumber } from '../utils/formatPhoneNumber';
import { useUser } from '../context/UserContext';
import { Input, Button, Select, ConfigProvider } from 'antd';

const ProfilePage = () => {
  const { user, updateUser } = useUser();
  const [firstName, setFirstName] = useState(user.first_name || '');
  const [lastName, setLastName] = useState(user.last_name || '');
  const [role, setRole] = useState(user.role || 'USER');
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState('USER');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const [inviteOpen, setInviteOpen] = useState(false);
  const [teamOpen, setTeamOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const users = await getUsers();
        const sortedUsers = users.sort((a, b) => {
          if (a.last_name < b.last_name) return -1;
          if (a.last_name > b.last_name) return 1;
          if (a.first_name < b.first_name) return -1;
          if (a.first_name > b.first_name) return 1;
          return 0;
        }).map(user => ({
          ...user,
          phone_number: formatPhoneNumber(user.phone_number)
        }));
        setTeamMembers(sortedUsers);
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    fetchTeamMembers();
  }, []);

  const handleInvite = async (event) => {
    event.preventDefault();
    setInviteLoading(true);
    try {
      await sendInvite(inviteEmail, inviteRole);
      setNotificationMessage('Invitation sent successfully');
      setNotificationSeverity('success');
    } catch (error) {
      setNotificationMessage(error.message || 'Failed to send invitation');
      setNotificationSeverity('error');
    } finally {
      setNotificationOpen(true);
      setInviteEmail('');
      setInviteRole('USER');
      setInviteLoading(false);
    }
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleProfileUpdate = async (event) => {
    event.preventDefault();
    const confirmed = window.confirm('Are you sure you want to update your profile?');
    if (confirmed) {
      setUpdateProfileLoading(true);
      try {
        await updateProfile({ first_name: firstName.trim(), last_name: lastName.trim() });
        updateUser({ first_name: firstName.trim(), last_name: lastName.trim() });
        setNotificationMessage('Profile updated successfully');
        setNotificationSeverity('success');
      } catch (error) {
        setNotificationMessage(error.message || 'Failed to update profile');
        setNotificationSeverity('error');
      } finally {
        setNotificationOpen(true);
        setUpdateProfileLoading(false);
      }
    }
  };

  const handleRoleChange = async (userId, newRole, userRole) => {
    if (userRole === 'SUPERADMIN' && role !== 'SUPERADMIN') {
      setNotificationMessage('Only a super admin can change the role of another super admin.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    if (user.email === teamMembers.find(member => member.user_id === userId).email) {
      setNotificationMessage('You cannot change your own role.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    const confirmed = window.confirm('Are you sure you want to change this user\'s role?');
    if (confirmed) {
      try {
        await updateUserRole(userId, newRole, userRole);
        const updatedTeamMembers = teamMembers.map(member =>
          member.user_id === userId ? { ...member, role: newRole } : member
        );
        setTeamMembers(updatedTeamMembers);
        setNotificationMessage('User role updated successfully');
        setNotificationSeverity('success');
      } catch (error) {
        setNotificationMessage(error.message || 'Failed to update user role');
        setNotificationSeverity('error');
      } finally {
        setNotificationOpen(true);
      }
    }
  };

  const handleDeleteUser = async (userId, userRole) => {
    const currentUserRole = user.role;
  
    if (userRole === 'SUPERADMIN' && currentUserRole !== 'SUPERADMIN') {
      setNotificationMessage('Only a super admin can delete another super admin.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }
  
    if (user.email === teamMembers.find(member => member.user_id === userId).email) {
      setNotificationMessage('You cannot delete yourself.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }
  
    const confirmed = window.confirm('Are you sure you want to delete this user?');
    if (confirmed) {
      try {
        await deleteUser(userId, currentUserRole);
        const updatedTeamMembers = teamMembers.filter(member => member.user_id !== userId);
        setTeamMembers(updatedTeamMembers);
        setNotificationMessage('User deleted successfully');
        setNotificationSeverity('success');
      } catch (error) {
        setNotificationMessage(error.message || 'Failed to delete user');
        setNotificationSeverity('error');
      } finally {
        setNotificationOpen(true);
      }
    }
  };

  return (
    <Grid container spacing={2} style={{ height: '100vh' }}>
      <Grid item style={{ width: '216px' }}>
        <SideMenu />
      </Grid>
      <ConfigProvider
        theme={{
            token: {
            colorPrimary: '#F57D20',
            },
        }}
        componentSize='large'
        >
      <Grid item xs style={{ marginTop: '20px' }}>
        <div style={{ width: '1100px', backgroundColor: 'white', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}>
          <Typography variant="h5" component="h1">
            Profile
          </Typography>
              <Box component="form" onSubmit={handleProfileUpdate} sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Input
                      required
                      placeholder='First Name'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Input
                      required
                      placeholder='Last Name'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      required
                      placeholder='Email Address'
                      value={user.email}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      required
                      placeholder='Phone Number'
                      value={formatPhoneNumber(user.phone_number)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                      <Select
                        labelId="role-select-label"
                        id="role-select"
                        value={role}
                        label="Role"
                        onChange={(value) => setRole(value)}
                        style={{ width: '100%' }}
                        disabled
                      >
                        <MenuItem value="USER">User</MenuItem>
                        <MenuItem value="ADMIN">Admin</MenuItem>
                        <MenuItem value="SUPERADMIN">Super Admin</MenuItem>
                      </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary" style={{ marginLeft: '3px' }}>
                      Date Joined: {user?.date_joined ? new Date(user.date_joined).toLocaleDateString() : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{  width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Button
                      type="primary"
                      loading={updateProfileLoading}
                      htmlType="submit"
                    >
                      Update Profile
                    </Button>
                  </Grid>
                </Grid>
              </Box>
        </div>
        {(role === 'ADMIN' || role === 'SUPERADMIN') && (
          <>
             <div style={{ marginTop: '20px', width: '1100px', backgroundColor: 'white', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}>
                <Typography variant="h6" component="div" onClick={() => setInviteOpen(!inviteOpen)} style={{ cursor: 'pointer' }}>
                  Invite User <ExpandMoreIcon />
                </Typography>
                <Collapse in={inviteOpen}>
                  <Box component="form" onSubmit={handleInvite} sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Input
                          required
                          placeholder='Invite Email Address'
                          value={inviteEmail}
                          onChange={(e) => setInviteEmail(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                          <Select
                            value={inviteRole}
                            label="Role"
                            onChange={(value) => setInviteRole(value)}
                            style={{ width: '100%' }}
                          >
                            <MenuItem value="USER">User</MenuItem>
                            <MenuItem value="ADMIN">Admin</MenuItem>
                            <MenuItem value="SUPERADMIN">Super Admin</MenuItem>
                          </Select>
                      </Grid>
                      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                        <Button
                          type="primary"
                          loading={inviteLoading}
                          htmlType="submit"
                        >
                          Send Invitation
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </div>
              <div style={{ marginTop: '20px', width: '1100px', backgroundColor: 'white', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}>
                <Typography variant="h6" component="div" onClick={() => setTeamOpen(!teamOpen)} style={{ cursor: 'pointer' }}>
                  Team <ExpandMoreIcon />
                </Typography>
                <Collapse in={teamOpen}>
                  <Box sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography variant="subtitle1">Name</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle1">Email</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle1">Phone Number</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle1">Role</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle1">Actions</Typography>
                      </Grid>
                      {teamMembers.map(member => (
                        <Grid item xs={12} key={member.user_id}>
                          <Card sx={{ mb: 2 }}>
                            <CardContent>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={2}>
                                  <Typography>{member.last_name}, {member.first_name}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography>{member.email}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography>{formatPhoneNumber(member.phone_number)}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                      value={member.role}
                                      label="Role"
                                      onChange={(value) => handleRoleChange(member.user_id, value, member.role)}
                                      disabled={(member.role === 'SUPERADMIN' && role !== 'SUPERADMIN') || member.email === user.email}
                                    >
                                      <MenuItem value="USER">User</MenuItem>
                                      <MenuItem value="ADMIN">Admin</MenuItem>
                                      <MenuItem value="SUPERADMIN">Super Admin</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={2}>
                                  <IconButton color="secondary" onClick={() => handleDeleteUser(member.user_id, member.role)} disabled={(member.role === 'SUPERADMIN' && role !== 'SUPERADMIN') || member.email === user.email}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Collapse>
              </div>
          </>
        )}
      </Grid>
      </ConfigProvider>
      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert onClose={handleNotificationClose} severity={notificationSeverity}>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </Grid>
    
  );
};

export default ProfilePage;
