import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, ConfigProvider, Select, Table, Flex, Empty, Modal, message, Input, DatePicker, Spin, Tooltip, notification } from 'antd';
import { SearchOutlined, PlusOutlined, DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { Typography, Box } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  fetchEvents,
  fetchSites,
  fetchDispatchableSnowRoutes,
  fetchDispatchableSaltRoutes,
  fetchDispatchableSaltRouteSites,
  fetchDispatchableSnowRouteSites,
  addSaltRouteSites,
  addSnowRouteSites,
  removeSaltRouteSite,
  removeSnowRouteSite,
  dispatchEventRoutes,
  fetchSubcontractors,
  updateSaltRouteSubcontractor,
  updateSnowSiteSubcontractor,
} from '../api';
import SideMenu from '../components/SideMenu';
import WeatherData from '../components/WeatherData';
import { useUser } from '../context/UserContext';
import { useCurrentEvent } from '../context/EventContext';
import { useConstants } from '../context/ConstantsContext';

const { Option } = Select;

dayjs.extend(utc);
dayjs.extend(timezone);

const DispatchPage = () => {
  const { user } = useUser();
  const { currentEvent, setCurrentEvent } = useCurrentEvent();
  const constants = useConstants();
  const [messageApi, contextHolder] = message.useMessage();

  const [activeEvents, setActiveEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [dispatchRoutes, setDispatchRoutes] = useState([]);
  const [subcontractors, setSubcontractors] = useState([]);
  const [sites, setSites] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingRoutes, setLoadingRoutes] = useState(false);
  const [loadingSubcontractors, setLoadingSubcontractors] = useState(false);
  const [loadingSites, setLoadingSites] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [allSelectedKeys, setAllSelectedKeys] = useState(new Set());
  const [filteredRowKeys, setFilteredRowKeys] = useState([]);

  const [dispatchTime, setDispatchTime] = useState(null);
  const [materialType, setMaterialType] = useState(null);
  const [dispatchNotes, setDispatchNotes] = useState(null);
  const [isConfirmDispatchOpen, setIsConfirmDispatchOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);


  const navigate = useNavigate();
  const navigateNotifyPage = () => {
    navigate('/notify');
  };

  const loadActiveEvents = useCallback(async () => {
    setLoadingEvents(true);
    try {
      const events = await fetchEvents({ active: 'true', order: 'desc' });
      const eventOptions = events.map(event => ({
        label: `${event.event_name} - ${event.service_type}`,
        value: event.event_id,
        type: event.event_type,
      }));
      setActiveEvents(eventOptions);

      if (currentEvent?.event_id && currentEvent?.event_type) {
        setSelectedEventId(currentEvent.event_id);
        setEventType(currentEvent.event_type);
      } else if (eventOptions.length > 0) {
        await setCurrentEvent(eventOptions[0].value);
        setSelectedEventId(eventOptions[0].value);
        setEventType(eventOptions[0].type);
      }
    } catch (error) {
      console.error('Error fetching active events:', error);
    } finally {
      setLoadingEvents(false);
    }
  }, [currentEvent, setCurrentEvent]);

  const loadDispatchableRoutes = useCallback(async () => {
    if (!selectedEventId || !eventType) return;
    setLoadingRoutes(true);
    try {
      let routes;
      if (eventType === constants.event_type.snow) {
        routes = await fetchDispatchableSnowRoutes(selectedEventId);
      } else if (eventType === constants.event_type.salt) {
        routes = await fetchDispatchableSaltRoutes(selectedEventId);

        routes = routes.map(route => {
          const routeSubcontractors = subcontractors
            .filter(sub => route.dispatchable_subcontractor_ids.includes(sub.value))
            .map(sub => ({
              label: sub.label,
              value: sub.value,
            }));
          return {
            ...route,
            routeSubcontractors, 
          };
        });
      }

      setDispatchRoutes(routes);
    } catch (error) {
      console.error(`Error fetching ${eventType} dispatch routes:`, error);
    } finally {
      setLoadingRoutes(false);
    }
  }, [selectedEventId, eventType, constants, subcontractors]);

  const loadSubcontractors = useCallback(async () => {
    setLoadingSubcontractors(true);
    try {
      const subs = await fetchSubcontractors();
      const subcontractorOptions = subs.map(sub => ({
        label: sub.name,
        value: sub.subcontractor_id,
      }));
      setSubcontractors(subcontractorOptions);
    } catch (error) {
      console.error('Error fetching subcontractors:', error);
    } finally {
      setLoadingSubcontractors(false);
    }
  }, []);

  const loadSites = useCallback(async () => {
    setLoadingSites(true);
    try {
      const sitesData = await fetchSites();
      const siteOptions = sitesData.map(site => ({
        label: site.site_name,
        value: site.site_id,
      }));
      setSites(siteOptions);
    } catch (error) {
      console.error('Error fetching all sites:', error);
    } finally {
      setLoadingSites(false);
    }
  }, []);

  useEffect(() => {
    loadActiveEvents();
  }, [loadActiveEvents]);
  
  useEffect(() => {
    loadSubcontractors();
  }, [loadSubcontractors]);
  
  useEffect(() => {
    if (subcontractors.length > 0 && selectedEventId && eventType) {
      loadDispatchableRoutes();
    }
  }, [selectedEventId, eventType, loadDispatchableRoutes, subcontractors]);
  
  useEffect(() => {
    loadSites();
  }, [loadSites]);

  const extractZoneName = (zoneName) => zoneName.split('R')[0];

  const uniqueZones = useMemo(() => {
    return Array.from(new Set(dispatchRoutes.map((route) => extractZoneName(route.zone_name))));
  }, [dispatchRoutes]);

  // Handle event change
  const handleEventChange = async (value, option) => {
    await setCurrentEvent(value); // set global event context
    setSelectedEventId(value);
    setEventType(option.type);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Update subcontractor for a route
  const handleUpdateSaltRouteSubcontractor = useCallback(async (dispatchRouteId, subcontractorId, subcontractorName) => {
    if (eventType === constants.event_type.snow) return;
    try {
      await updateSaltRouteSubcontractor(dispatchRouteId, subcontractorId);
      setDispatchRoutes(prevRoutes =>
        prevRoutes.map(route =>
          route.id === dispatchRouteId
            ? { ...route, subcontractor_id: subcontractorId, subcontractor_name: subcontractorName }
            : route
        )
      );
    } catch (error) {
      console.error(`Error updating salt route subcontractor:`, error);
    }
  }, [constants, eventType]);

  const filteredDispatchRoutes = useMemo(() => {
    return dispatchRoutes
      .filter((dispatchRoute) => selectedZone === '' || extractZoneName(dispatchRoute.zone_name) === selectedZone)
      .filter((dispatchRoute) => dispatchRoute.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [dispatchRoutes, selectedZone, searchQuery]);

  useEffect(() => {
    const currentFilteredKeys = filteredDispatchRoutes.map(route => route.key);
    setFilteredRowKeys([...allSelectedKeys].filter(key => currentFilteredKeys.includes(key)));
  }, [filteredDispatchRoutes, allSelectedKeys]);

  const onSelectChange = (newSelectedKeys) => {
    const updatedSelectedKeys = new Set(allSelectedKeys);
    newSelectedKeys.forEach(key => updatedSelectedKeys.add(key));
    filteredRowKeys.forEach(key => {
      if (!newSelectedKeys.includes(key)) updatedSelectedKeys.delete(key);
    });
    setAllSelectedKeys(updatedSelectedKeys);
    setFilteredRowKeys(newSelectedKeys);
  };

  const rowSelection = {
    selectedRowKeys: filteredRowKeys,
    onChange: onSelectChange,
  };

  // Confirm dispatch for routes
  const handleConfirmDispatch = async () => {
    setConfirmLoading(true);
    try {
      const dispatchTimeUtc = dayjs(dispatchTime).utc().format();

      const response = await dispatchEventRoutes(selectedEventId, {
        routes: [...allSelectedKeys],
        dispatch_time: dispatchTimeUtc,
        material_type: materialType,
        dispatch_notes: dispatchNotes,
        user_id: user.user_id,
      });
      console.log('Routes Dispatched:', response);

      if (response.data && response.data.successes) {
        response.data.successes.forEach(successMessage => {
          messageApi.success(successMessage);
        });
      }

      if (response.data && response.data.errors) {
        response.data.errors.forEach(errMessage => {
          showError(errMessage);
        });
      }
    } catch (error) {
      console.error('Error during dispatch:', error);
      if (error.response && error.response.data) {
        if (error.response.data.errors) {
          error.response.data.errors.forEach(errMessage => {
            showError(errMessage);
          });
        } else {
          messageApi.error('An error occurred during dispatch. Please try again.');
        }
      } else {
        messageApi.error('An error occurred. Please check your network or server status.');
      }
    } finally {
      await loadDispatchableRoutes();
      setFilteredRowKeys([]);
      setAllSelectedKeys(new Set());
      setConfirmLoading(false);
      setDispatchTime(null);
      setMaterialType(null);
      setIsConfirmDispatchOpen(false);
    }
  };

  const handleCancelConfirm = () => {
    setMaterialType(null);
    setDispatchNotes(null);
    setIsConfirmDispatchOpen(false);
  };

  const routeColumns = useMemo(() => {
    const columns = [
      {
        title: 'Route',
        dataIndex: 'name',
        key: 'route',
        width: '40%',
        ellipsis: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Zone',
        dataIndex: 'zone_name',
        key: 'zone',
        align: 'center',
        ellipsis: true,
        sorter: (a, b) => a.zone_name.localeCompare(b.zone_name),
      },
      // {
      //   title: 'Temperature',
      //   dataIndex: 'temperature',
      //   key: 'temperature',
      //   align: 'center',
      //   ellipsis: true,
      //   sorter: (a, b) => a.temperature - b.temperature,
      // },
    ];

    if (eventType === constants?.event_type?.salt) {
      columns.push({
        title: (
          <Tooltip
          title={
            <>
              Only subcontractors who have been assigned this route may be selected for dispatch.<br />
              Salt routes require a route/subcontractor record which tracks the subcontractors' saltings performed on the route.<br />
              Create one on the subcontractor page if you would like to assign an unlisted subcontractor.
            </>
          }
          >
            <span>Subcontractor</span>
          </Tooltip>
        ),
        key: 'subcontractor',
        width: '35%',
        render: (text, record) => {
          const routeSubcontractors = record.routeSubcontractors || [];
          const isDefaultSubcontractorAvailable = routeSubcontractors.some(
            (sub) => sub.value === record.subcontractor_id
          );
          const selectedSubcontractor = isDefaultSubcontractorAvailable
            ? record.subcontractor_id
            : null; // If not available, set to null
  
          return (
            <Select
              value={selectedSubcontractor}
              showSearch
              placeholder="Select subcontractor"
              options={routeSubcontractors}
              style={{ width: '100%' }}
              onChange={(value, option) => handleUpdateSaltRouteSubcontractor(record.id, value, option.label)}
              optionFilterProp="label"
            />
          );
        },
      });
    }
  
    return columns;
  }, [eventType, constants, handleUpdateSaltRouteSubcontractor]);

  // Expanded row component for each route
  const ExpandedDispatchRouteRow = ({ dispatch_route_id, dispatch_type, sites }) => {
    const [dispatchSites, setDispatchSites] = useState([]);
    const [availableSites, setAvailableSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null);
    const [confirmingSiteRemoval, setConfirmingSiteRemoval] = useState(null);
    const [confirmingSiteElement, setConfirmingSiteElement] = useState(null);
    const [siteToRemove, setSiteToRemove] = useState(null);
    const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loadingRouteSites, setLoadingRouteSites] = useState(false);

    const loadRouteSites = useCallback(async () => {
      setLoadingRouteSites(true);
      try {
        let data;
        if (dispatch_type === constants?.event_type.snow) {
          data = await fetchDispatchableSnowRouteSites(dispatch_route_id);
        } else {
          data = await fetchDispatchableSaltRouteSites(dispatch_route_id);
        }
        const nonCancelledSites = data.filter(site => !site.cancelled_at);
        setDispatchSites(nonCancelledSites);
      } catch (error) {
        console.error(`Error fetching ${dispatch_type} route sites:`, error);
      } finally {
        setLoadingRouteSites(false);
      }
    }, [dispatch_route_id, dispatch_type]);

    useEffect(() => {
      if (sites.length > 0) {
        if (dispatchSites.length === 0) {
          const siteOptions = sites.map(site => ({
            label: site.label,
            value: site.value,
          }));
          setAvailableSites(siteOptions);
        } else {
          const filteredSites = sites.filter(
            site => !dispatchSites.some(assignedSite => assignedSite.site_id === site.value)
          );
          const siteOptions = filteredSites.map(site => ({
            label: site.label,
            value: site.value,
          }));
          setAvailableSites(siteOptions);
        }
      } else {
        console.log("No sites available for filtering.");
      }
    }, [dispatchSites, sites]);

    useEffect(() => {
      loadRouteSites();
    }, [loadRouteSites]);

    const handleAddSelectedSite = async () => {
      try {
        if (dispatch_type === constants.event_type.snow) {
          await addSnowRouteSites(dispatch_route_id, { site_ids: [selectedSite] });
        } else {
          await addSaltRouteSites(dispatch_route_id, { site_ids: [selectedSite] });
        }
        await loadRouteSites();
        setSelectedSite(null);
      } catch (error) {
        console.error(`Error adding site to ${dispatch_type} route:`, error);
      }
    };

    const handleRemoveSite = useCallback(async (siteToRemove, event) => {
      if (confirmingSiteRemoval === siteToRemove.id) {
        if (siteToRemove?.notified_at) {
          // Show cancellation modal
          setSiteToRemove(siteToRemove);
          setIsCancellationModalOpen(true);
        } else {
          try {
            if (dispatch_type === constants.event_type.snow) {
              await removeSnowRouteSite(siteToRemove.id);
            } else {
              await removeSaltRouteSite(siteToRemove.id);
            }
            await loadRouteSites();
          } catch (error) {
            console.error(`Error removing site from ${dispatch_type} route:`, error);
          }
        }
        setConfirmingSiteRemoval(null);
        setConfirmingSiteElement(null);
      } else {
        setConfirmingSiteRemoval(siteToRemove.id);
        setConfirmingSiteElement(event.currentTarget);
      }
    }, [confirmingSiteRemoval, dispatch_type, loadRouteSites]);

    const handleCancelSite = async (sendCancellationNotice) => {
      setConfirmLoading(true);
      try {
        if (siteToRemove) {
          if (dispatch_type === constants.event_type.snow) {
            await removeSnowRouteSite(siteToRemove.id, sendCancellationNotice);
          } else {
            await removeSaltRouteSite(siteToRemove.id, sendCancellationNotice);
          }
          message.success('Site removed successfully');
        }
        await loadRouteSites();
        setIsCancellationModalOpen(false);
      } catch (error) {
        message.error('Error removing site');
      } finally {
        setConfirmLoading(false);
        setSiteToRemove(null);
      }
    };

    const handleCancelModalClose = () => {
      setIsCancellationModalOpen(false);
      setSiteToRemove(null);
    };

    const handleSelectSite = (siteId) => {
      setSelectedSite(siteId);
    };

    const handleUpdateSnowSiteSubcontractor = useCallback(async (site, subcontractorId) => {
      try {
        await updateSnowSiteSubcontractor(dispatch_route_id, site.id, subcontractorId);
        await loadRouteSites();
      } catch (error) {
        console.error('Error updating subcontractor for site:', error);
      }
    }, [dispatch_route_id, loadRouteSites]);

    // Detect outside click to reset the delete button
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (confirmingSiteElement && !confirmingSiteElement.contains(event.target)) {
          setConfirmingSiteRemoval(null);
          setConfirmingSiteElement(null);
        }
      };
  
      if (confirmingSiteElement) {
        document.addEventListener('click', handleOutsideClick);
      }
  
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [confirmingSiteElement]);

    const siteColumns = useMemo(() => {
      const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: '40%',
        },
        {
          title: 'City',
          dataIndex: 'city',
          key: 'city',
        },
      ];
    
      if (eventType === constants?.event_type?.snow) {
        columns.push({
          title: 'Subcontractor',
          key: 'subcontractor',
          render: (_, record) => (
            <Select
              value={record.subcontractor_id}
              showSearch
              placeholder="Search subcontractors"
              options={subcontractors}
              style={{ width: '100%' }}
              onChange={(value) => handleUpdateSnowSiteSubcontractor(record, value)}
              optionFilterProp="label"
            />
          ),
        });
      }

      columns.push({
        title: 'Operation',
        dataIndex: 'operation',
        width: '130px',
        align: 'center',
        render: (_, record) => {
          const isConfirming = confirmingSiteRemoval === record.id;
          return (
            <Button
              shape="round"
              type={isConfirming ? 'primary' : 'text'}
              danger={isConfirming}
              icon={isConfirming ? null : <DeleteOutlined />}
              onClick={(event) => handleRemoveSite(record, event)}
            >
              {isConfirming ? 'Remove Site' : ''}
            </Button>
          );
        },
      });
    
      return columns;
    }, [confirmingSiteRemoval, handleRemoveSite, handleUpdateSnowSiteSubcontractor]);

    if (loadingRouteSites || loadingSites || loadingSubcontractors) {
      return <Spin />;
    }

    return (
      <>
        <Flex vertical gap="large">
          <Flex vertical={false} justify="space-between" align="center" gap="large">
            <em>Add or remove sites for this route</em>
            <Flex vertical={false} justify="flex-end" gap="small">
              <Select
                value={selectedSite}
                showSearch
                placeholder="Search sites"
                options={availableSites}
                style={{ width: 400 }}
                onChange={handleSelectSite}
                optionFilterProp="label"
              />
              <Button disabled={!selectedSite} icon={<PlusOutlined />} onClick={handleAddSelectedSite}>
                Add Site
              </Button>
            </Flex>
          </Flex>
          <Table
            pagination={false}
            columns={siteColumns}
            dataSource={dispatchSites}
            size="small"
            showHeader={false}
            locale={{
              emptyText: 'No sites have been selected for this route',
            }}
          />
        </Flex>

        <Modal
          title="Service Cancellation"
          open={isCancellationModalOpen}
          onCancel={handleCancelModalClose}
          footer={[
            <Button key="no" onClick={() => handleCancelSite(false)}>
              No
            </Button>,
            <Button key="yes" type="primary" onClick={() => handleCancelSite(true)} loading={confirmLoading}>
              Yes
            </Button>,
          ]}
        >
          <p>The client has already been notified of site servicing.</p>
          <p>Do you want to send a service cancellation letter to the client for this site?</p>
        </Modal>
      </>
    );
  };

  const showError = (errorMessage) => {
    const messageWithLineBreaks = errorMessage.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  
    notification.error({
      message: 'Error',
      description: (
        <div style={{ textAlign: 'left' }}>
          {messageWithLineBreaks}
        </div>
      ),
      duration: 0,
      closable: true,
    });
  };

  const renderLoadingState = () => (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin size="large" tip="Loading...">
        <div style={{ width: 0, height: 0 }} />
      </Spin>
    </div>
  );

  const renderTable = () => (
    <Table
      columns={routeColumns}
      dataSource={filteredDispatchRoutes}
      rowSelection={rowSelection}
      pagination={false}
      scroll={{ y: tableHeight }}
      sticky
      expandable={{
        expandedRowRender: (dispatchRoute) => (
          <ExpandedDispatchRouteRow
            dispatch_route_id={dispatchRoute.id}
            dispatch_type={eventType}
            sites={sites}
          />
        ),
      }}
      locale={{
        emptyText: (
          <Empty description={`No ${eventType} routes available for dispatch. To get started, notify some sites first.`} />
        ),
      }}
    />
  );

  const [tableHeight, setTableHeight] = useState('100vh');
  const titleBoxRef = useRef(null);
  const actionBoxRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const calculateTableHeight = () => {
      const headerHeight = 72;
      const titleBoxHeight = titleBoxRef.current ? titleBoxRef.current.offsetHeight : 32;
      const actionBoxHeight = actionBoxRef.current ? actionBoxRef.current.offsetHeight : 32;
      const padding = 16;

      // Calculate height of the table header
      const tableHeaderHeight = tableRef.current ? tableRef.current.querySelector('.ant-table-thead').offsetHeight : 48;

      const totalHeightAroundTableRows =
        headerHeight +
        titleBoxHeight +
        actionBoxHeight +
        padding * 6 +
        tableHeaderHeight;

      setTableHeight(`calc(100vh - ${totalHeightAroundTableRows}px)`);
    };

    if (selectedEventId) {
        calculateTableHeight();
    }

    window.addEventListener('resize', calculateTableHeight);
    return () => window.removeEventListener('resize', calculateTableHeight);
  }, [selectedEventId]);

  // if (loadingEvents) {
  //   return renderLoadingState();
  // }

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#F57D20', colorLinkHover: '#F57D20', colorLink: '#F57D20' } }}>
      {contextHolder}
      <Box display="flex">
        {/* Side Menu */}
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        
        {/* Main Content */}
        <Card
          style={{
            height: 'calc(100vh - 72px - 16px)',
            marginRight: '16px',
            overflow: 'hidden',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
          }}
        >

          {/* Header and Event Selection */}
          <Box ref={titleBoxRef} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <Typography variant="h5" component="div">
              {constants && eventType
                ? `Dispatch ${eventType === constants.event_type.snow ? 'Snow' : 'Salt'} Routes`
                : 'Dispatch Routes'}
            </Typography>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Select
                value={selectedEventId}
                onChange={handleEventChange}
                style={{ width: 320 }}
                placeholder="Select an Event"
                options={activeEvents}
              />
            </Box>
          </Box>

          {/* Search, Filters, and Action Buttons */}
          <Box ref={actionBoxRef} display="flex" justifyContent="space-between" alignItems="center" marginTop="16px">
            <Box display="flex" alignItems="center" gap={2}>
              {/* Search Box */}
              <Input
                placeholder="Search Routes"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ width: '300px' }}
                prefix={<SearchOutlined style={{ color: '#d9d9d9' }} />}
              />

              {/* Zone Selector */}
              <Select
                value={selectedZone}
                onChange={(value) => setSelectedZone(value)}
                style={{ minWidth: '100px' }}
              >
                <Select.Option value="">All Zones</Select.Option>
                {uniqueZones.map((zone) => (
                  <Select.Option key={zone} value={zone}>
                    {zone}
                  </Select.Option>
                ))}
              </Select>
            </Box>

            <Button
              disabled={allSelectedKeys.size < 1}
              onClick={() => setIsConfirmDispatchOpen(true)}
              style={{
                backgroundColor: allSelectedKeys.size > 0 ? 'black' : '#f5f5f5', 
                color: allSelectedKeys.size > 0 ? '#f57e1f' : '#d9d9d9', 
                borderColor: allSelectedKeys.size > 0 ? 'black' : '#d9d9d9', 
                cursor: allSelectedKeys.size > 0 ? 'pointer' : 'not-allowed',
                transition: 'background-color 0.3s ease, color 0.3s ease',
              }}
              onMouseEnter={(e) => {
                if (allSelectedKeys.size > 0) {
                  e.currentTarget.style.backgroundColor = '#333';
                  e.currentTarget.style.color = '#ff9900';
                }
              }}
              onMouseLeave={(e) => {
                if (allSelectedKeys.size > 0) {
                  e.currentTarget.style.backgroundColor = 'black';
                  e.currentTarget.style.color = '#f57e1f';
                }
              }}
              type="primary"
            >
              Dispatch Routes
            </Button>
          </Box>

          {/* Scrollable Table */}
          <Box ref={tableRef} sx={{ overflow: 'hidden', marginTop: '16px' }}>
            {loadingRoutes ? renderLoadingState() : renderTable()}
          </Box>
        </Card>
      </Box>

      <Modal
        title="Confirm Dispatch"
        open={isConfirmDispatchOpen}
        onOk={handleConfirmDispatch}
        onCancel={handleCancelConfirm}
        confirmLoading={confirmLoading}
        okButtonProps={{
          disabled:
            !dispatchTime ||
            (eventType === constants?.event_type.salt && !materialType),
        }}
      >
        <div>
          <Typography>Dispatch Notes:</Typography>
          <Input.TextArea
            value={dispatchNotes}
            onChange={(e) => setDispatchNotes(e.target.value)}
            placeholder="Enter dispatch notes"
            rows={3}
          />
        </div>

        <div>
          <Typography>Dispatch Time:</Typography>
          <DatePicker
            showTime
            value={dispatchTime ? dayjs(dispatchTime) : null}
            onChange={(value) => setDispatchTime(value)}
          />
          <Button onClick={() => setDispatchTime(dayjs())}>Now</Button>
        </div>

        {/* Conditionally render fields for salt dispatch */}
        {eventType === constants?.event_type.salt && (
          <>
            <div>
              <Typography>Material Type:</Typography>
              <Select
                value={materialType}
                onChange={setMaterialType}
                style={{ width: '100%' }}
              >
                <Select.Option value={constants.material_type.salt}>Salt</Select.Option>
                <Select.Option value={constants.material_type.brine}>Brine</Select.Option>
              </Select>
            </div>
          </>
        )}
      </Modal>
    </ConfigProvider>
  );
};

export default DispatchPage;