import React, { useState, useEffect } from 'react';
import { Button, ConfigProvider, Progress, Tag, message, Modal } from 'antd';
import { Box, Typography, CircularProgress } from '@mui/material';
import RenderData from '../components/RenderData';
import SideMenu from '../components/SideMenu';
import SyncServiceHistories from '../components/SyncServiceHistories';
import { fetchEvents } from '../api';
import { useConstants } from '../context/ConstantsContext';

const tagsData = ['All', 'Active', 'Snow', 'Salt'];

const EventsPage = () => {
  const constants = useConstants();
  const [constantsLoading, setConstantsLoading] = useState(true);
  const [selectedTags, setSelectedTags] = React.useState(['All']);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [syncModalOpen, setSyncModalOpen] = useState(false);

  useEffect(() => {
    if (constants) {
      setConstantsLoading(false);
    }
  }, [constants]);

  useEffect(() => {
    const loadEvents = async () => {
      setLoading(true);
      try {
        const response = await fetchEvents();
        console.log('Fetched events:', response);
        setEvents(response);
      } catch (error) {
        message.error('Failed to fetch events');
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, []);

  const handleTagChange = (tag, checked) => {
    if (tag === 'All') {
      setSelectedTags(checked ? ['All'] : []);
    } else {
      const nextSelectedTags = checked
        ? [...selectedTags.filter((t) => t !== 'All'), tag]
        : selectedTags.filter((t) => t !== tag);
      setSelectedTags(nextSelectedTags.length > 0 ? nextSelectedTags : ['All']);
    }
  };

  const filteredEvents = selectedTags.includes('All')
  ? events
  : events.filter(event => {
      const matchesEventTypeSalt = selectedTags.includes('Salt') && event.event_type === constants.event_type.salt;
      const matchesEventTypeSnow = selectedTags.includes('Snow') && event.event_type === constants.event_type.snow;
      const allTypes = !selectedTags.includes('Snow') && !selectedTags.includes('Salt');
      const matchesStatus = selectedTags.includes('Active') ? event.status === constants.status.active : true;

      return matchesStatus && (matchesEventTypeSalt || matchesEventTypeSnow || allTypes);
  });

  const handleDetailClick = (event) => {
    setSelectedEvent(event);
    setDetailOpen(true);
  };

  const handleDetailClose = () => {
    setSelectedEvent(null);
    setDetailOpen(false);
  };

  if (constantsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F57D20',
          colorLinkHover: '#F57D20',
          colorLink: '#F57D20',
        },
      }}
    >
      <Box display="flex" sx={{ width: '98vw', marginTop: '20px' }}>
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <span style={{ fontSize: '36px', fontWeight: '500', color: 'black' }}>Events</span>
          <br />

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box>
              {tagsData.map((tag) => (
                <Tag.CheckableTag
                  key={tag}
                  checked={selectedTags.includes(tag)}
                  onChange={(checked) => handleTagChange(tag, checked)}
                  style={{ padding: '5px 10px', margin: '5px', fontSize: '14px' }}
                >
                  {tag}
                </Tag.CheckableTag>
              ))}
            </Box>

            {/* Sync Service Histories Button */}
            <Button
              type="primary"
              onClick={() => setSyncModalOpen(true)}
            >
              Sync Service Histories
            </Button>
          </Box>
          <Box 
            style={{
              width: '100%',
              display: 'flex',
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: 'row',
              gap: '20px',
              marginTop: '20px',
              flexWrap: 'wrap'
            }}
          >
            {loading? (
              <Typography>Loading events...</Typography>
            ) : (
              filteredEvents.map((event) => (
                  <Box
                    key={event.id}
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '5px',
                      width: '260px',
                      height: '260px',
                      padding: '15px',
                      display: 'flex',
                      flexDirection: 'column',
                      position: "relative",
                      boxShadow: event.status === constants.status.active ? '0 0 15px rgba(245, 125, 32, 0.6)' : '0 0 10px rgba(0, 0, 0, 0.1)',
                      border: event.status === constants.status.active ? "2px solid #F57D20" : "2px solid white",
                      overflow: 'hidden',
                    }}
                  >
                    {/* Top section: Event name on the left, status and event type on the right */}
                    <Box 
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        marginBottom: '10px',
                      }}
                    >
                      <Typography 
                        style={{
                          fontSize: '24px',
                          fontWeight: '600',
                          color: "black"
                        }}
                      >
                        {event.event_name}
                      </Typography>
                      
                      {/* Event Status and Event Type */}
                      <Box style={{ textAlign: 'right' }}>
                        <Progress
                          type="circle"
                          format={() => `${Math.round((event.num_sites_serviced / event.num_sites_dispatched) * 100) || 0}%`}
                          percent={Math.round((event.num_sites_serviced / event.num_sites_dispatched) * 100) || 0}
                          size={40}
                          strokeColor="#F57D20"
                          strokeWidth={18}
                          style={{ marginRight: '15px' }}
                        />
                        <Tag 
                          color={event.event_type === constants.event_type.snow ? "blue" : "green"} 
                          style={{ marginTop: '5px', fontSize: '12px' }}
                        >
                          {event.event_type}
                        </Tag>
                      </Box>
                    </Box>

                    {/* Event service type */}
                    {event.service_type && (
                      <Typography style={{ fontSize: '14px', fontWeight: '500', color: "#737373", marginBottom: '5px' }}>
                        {event.service_type}
                      </Typography>
                    )}

                    {/* Below Top section: Event details */}
                    <Typography style={{ fontSize: '14px', fontWeight: '400', color: "#737373", marginBottom: '5px' }}>
                      {event.event_start_date}
                    </Typography>
                    {event.status === constants.status.inactive && event.num_sites_dispatched > 0 && (
                      <Typography style={{ fontSize: '14px', fontWeight: '400', color: "#737373", marginBottom: '5px' }}>
                        IOS Score: {Math.round((event.num_sites_serviced / event.num_sites_dispatched) * 100)}%
                      </Typography>
                    )}

                    {event.status === constants.status.inactive && event.num_sites_dispatched === 0 && (
                      <Typography style={{ fontSize: '14px', fontWeight: '400', color: "#737373", marginBottom: '5px' }}>
                        IOS Score: N/A
                      </Typography>
                    )}
                    <Typography style={{ fontSize: '14px', fontWeight: '400', color: "#737373", marginBottom: '5px' }}>
                      Locations:
                    </Typography>
                    {event.forecasts.slice(0, 3).map((forecast, idx) => (
                      <Typography key={forecast.station_name} style={{ fontSize: '14px', fontWeight: '500', color: "black", paddingLeft: '8px' }}>
                        {forecast.country === "Canada" ? <>🇨🇦{" "}</> : <>🇺🇸{" "}</>}
                        {forecast.station_name}
                      </Typography>
                    ))}
                    {event.forecasts.length - 3 > 0 && (
                      <Typography style={{ fontSize: '14px', fontWeight: '500', color: "black" }}>
                        + {event.forecasts.length - 3} more locations
                      </Typography>
                    )}

                    {/* Bottom: Action buttons */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px',
                        position: 'absolute',
                        bottom: '15px',
                        right: '15px',
                        cursor: 'pointer',
                        color: 'black',
                        '&:hover': { color: "#F57D20" },
                        transition: 'all 0.2s ease-in-out',
                      }}
                    >
                      <Button size="small">
                        Edit
                      </Button>
                      <Button type="primary" size="small" onClick={() => handleDetailClick(event)}>
                        View
                      </Button>
                    </Box>
                  </Box>
              ))
            )}

            {/* Event Details Modal */}
            <Modal
              title="Event Details"
              open={detailOpen}
              onCancel={handleDetailClose}
              footer={null}
              width='60%'
            >
              <RenderData data={selectedEvent} />
            </Modal>

            {/* Sync Service Histories Modal */}
            <SyncServiceHistories
              open={syncModalOpen}
              onClose={() => setSyncModalOpen(false)}
            />
          </Box>
        </Box>
      </Box>
    </ConfigProvider>
  );
};

export default EventsPage;