import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchConstants } from '../api';

const ConstantsContext = createContext();

export const ConstantsProvider = ({ children }) => {
  const [constants, setConstants] = useState(null);

  useEffect(() => {
    const loadConstants = async () => {
      const constantsData = await fetchConstants();
      setConstants(constantsData);
    };

    loadConstants();
  }, []);

  return (
    <ConstantsContext.Provider value={constants}>
      {children}
    </ConstantsContext.Provider>
  );
};

export const useConstants = () => useContext(ConstantsContext);