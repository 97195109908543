import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Box, TextField, Snackbar, Alert } from '@mui/material';
import { Input, Button, ConfigProvider } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import '../styles/LoginPage.css';
import { sendPasswordResetEmail } from '../api';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setIsLoading(true);
    try {
      await sendPasswordResetEmail(email);
      setNotificationMessage('If an account with that email exists, a password reset link has been sent.');
      setNotificationSeverity('success');
      setNotificationOpen(true);
    } catch (error) {
      console.error('Error sending reset email:', error);
      setNotificationMessage('An error occurred while processing your request. Please try again later.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleResetPassword();
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F57D20',
        },
      }}
    >
      <div className="container">
        <motion.div 
          className="main"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 30,
          }}
        >
          <div className="textStack">
            <span className="title">Forgot Password</span>
            <span className="subtitle">
              Enter your email address to reset your password.
            </span>
          </div>
          <div className="fieldStack">
            <Input
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)', marginRight: '5px' }} />}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={handleKeyPress}
              className='textField'
            />
          </div>
          <Button 
            type="primary" 
            onClick={handleResetPassword} 
            className="submitButton" 
            block
            loading={isLoading}
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Reset Password'}
          </Button>
          <Typography className="switchForm" onClick={() => navigate('/login')}>
            Back to Login
          </Typography>
        </motion.div>
        <Snackbar
          open={notificationOpen}
          autoHideDuration={6000}
          onClose={() => setNotificationOpen(false)}
        >
          <Alert onClose={() => setNotificationOpen(false)} severity={notificationSeverity}>
            {notificationMessage}
          </Alert>
        </Snackbar>
      </div>
    </ConfigProvider>
  );
};

export default ForgotPasswordPage;