// SubcontractorsPage.js
import React, { useState, useEffect } from 'react';

import {
  fetchEquipment,
  updateEquipment,
} from '../api';
import { Typography, Box, Card, CardContent } from '@mui/material';
import SideMenu from '../components/SideMenu';
import { Input, Tooltip, ConfigProvider, Table, message } from 'antd';
import { EditOutlined, InfoCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

const EquipmentPage = () => {
  const [equipment, setEquipment] = useState([]);
  const [editingKey, setEditingKey] = useState();
  const [editingValue, setEditingValue] = useState();
  const [error, setError] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    loadEquipment();
  }, []);

  const loadEquipment = async () => {
    try {
      const data = await fetchEquipment();
      setEquipment(data.sort(function(a,b) {
        return a.id - b.id
      }));
    } catch (err) {
      setError(err.message);
    }
  };

  const handleUpdateCustomSubcontractorRate = async (equipment) => {
    await updateEquipment(equipment.id, editingValue);
    messageApi.success('Default equipment rate updated');
    loadEquipment();
    setEditingKey(null);
    setEditingValue(null);
  };

  const cancelEditRate = () => {
    setEditingKey(null);
    setEditingValue(null);
  }

  const editRate = (equipment) => {
    setEditingKey(equipment.id);
    setEditingValue(equipment.custom_contractor_rate ? equipment.custom_contractor_rate : equipment.contractor_rate);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <p>
          Yeti Subcontractor Rate <Tooltip title="Represents the contractor rate as defined in Yeti."><InfoCircleOutlined /></Tooltip>
        </p>
      ),
      dataIndex: 'contractor_rate',
      key: 'contractor_rate',
      render: (contractor_rate) => `$ ${contractor_rate.toFixed(2)}`
    },
    {
        title: (
          <p>
            Custom Subcontractor Rate <Tooltip title="Represents the contractor rate as defined here. Overrides the yeti rate."><InfoCircleOutlined /></Tooltip>
          </p>
        ),
        key: 'custom_contractor_rate',
        render: (equipment) =>
            editingKey === equipment.id ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        style={{ width: '60%', marginRight: '8px' }}
                        autoFocus
                    />
                    <CheckOutlined
                        onClick={() => handleUpdateCustomSubcontractorRate(equipment)}
                        style={{ color: 'green', cursor: 'pointer', marginRight: '8px' }}
                    />
                    <CloseOutlined
                        onClick={cancelEditRate}
                        style={{ color: 'red', cursor: 'pointer' }}
                    />
                </div>
            ) : (
              <span
                onClick={() => editRate(equipment)}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                {equipment.custom_contractor_rate ? `$ ${equipment.custom_contractor_rate.toFixed(2)}` : ""}
                <EditOutlined style={{ fontSize: '0.8em', color: 'gray' }} />
              </span>
          )
    },
  ]

  if (error) {
    return <Typography variant="h6" color="error">Error: {error}</Typography>;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
        colorPrimary: '#F57D20',
        colorLinkHover: '#F57D20',
        colorLink: '#F57D20',
        },
      }}
    >
      {contextHolder}
      <Box display="flex" style={{ width: '98vw', marginTop: '20px' }}>
        <Box width="250px">
          <SideMenu />
        </Box>
        <Box flexGrow={1}>
        <Card style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', }}>
          <CardContent>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '5px', flexDirection: "column" }}>
                <Typography variant="h5" component="div">
                  Equipment
                </Typography>
              </Box>
            </Box>
            <Table
              columns={columns}
              dataSource={equipment}
              pagination={false}
              rowKey={(record) => record.id}
            />
          </CardContent>
        </Card>
        </Box>
      </Box>
    </ConfigProvider>
  );
};


export default EquipmentPage;
