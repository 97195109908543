import React, { useState } from 'react';
import { Descriptions, Tag, List, Typography, Divider, Card, Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useConstants } from '../context/ConstantsContext';
import { fetchForecastPdfUrl } from '../api';
import { formatUtcDateTime } from '../utils/dateUtils';


const InvoiceDetails = ({ invoice }) => {
  const constants = useConstants();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const statusColour = (status) => {
    if (status === constants.status.pending) {
        return 'red';
    }
    if (status === constants.status.awaiting) {
        return '#DAA520';
    }
    if (status === constants.status.approved) {
        return 'green';
    }
  };

  if (!invoice) {
    return <Typography>No invoice data available</Typography>;
  }

  const onDownloadForecastPdf = async (forecastId) => {
    setLoadingPdf(true);
    try {
      const response = await fetchForecastPdfUrl(forecastId);
      const pdfUrl = response.pdf_url;
      if (pdfUrl) {
        window.open(pdfUrl, '_blank');
      } else {
        messageApi.error('PDF URL not available.');
      }
    } catch (error) {
      console.error('Error fetching forecast PDF:', error);
      messageApi.error('Failed to fetch the forecast PDF.');
    } finally {
      setLoadingPdf(false);
    }
  };

  const renderServiceHistories = () => {
    if (invoice.service_histories.length === 0) {
      return <Typography>No service histories available</Typography>;
    }
  
    return (
      <List
        itemLayout="horizontal"
        bordered
        dataSource={invoice.service_histories}
        renderItem={(history) => (
          <Card>
            <Descriptions title={`Service History ID: ${history.id}`} bordered column={1} size="small" labelStyle={{ width: '150px' }}>
              <Descriptions.Item label="Event Name">{history.event_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Route Name">{history.route_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Subcontractor Name">{history.subcontractor_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Site Name">{history.site_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Client Name">{history.client_name || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Services">{history.services || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Started At">{formatUtcDateTime(history.started_at)}</Descriptions.Item>
              <Descriptions.Item label="Ended At">{formatUtcDateTime(history.ended_at)}</Descriptions.Item>
              <Descriptions.Item label="Duration">{history.duration ? `${history.duration} hours` : 'N/A'}</Descriptions.Item>
  
              <Descriptions.Item label="Equipment Used">
                {history.equipment && history.equipment.length > 0 ? (
                  <List
                    dataSource={history.equipment}
                    renderItem={(equipment) => (
                      <List.Item>
                        <Typography>{equipment.name} - {(equipment.calculated_rate) ? `${equipment.calculated_rate} $/hr` : 'Rate Unavailable'}</Typography>
                      </List.Item>
                    )}
                    bordered={false}
                    size="small"
                  />
                ) : (
                  <Typography>N/A</Typography>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        )}
      />
    );
  };

  return (
    <>
      {contextHolder}
      {/* Invoice Details */}
      <Descriptions title="Invoice Details" bordered column={1} labelStyle={{ width: '150px' }}>
        <Descriptions.Item label="Invoice ID">{invoice.invoice_id}</Descriptions.Item>
        <Descriptions.Item label="Contract Type">{invoice.contract_type}</Descriptions.Item>
        <Descriptions.Item label="Total Duration">{invoice.total_duration.toFixed(4)} hours</Descriptions.Item>
        <Descriptions.Item label="Total Amount">${invoice.total_amount_sum.toFixed(2)}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color={statusColour(invoice.status)}>
            {invoice.status}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Created By">{invoice.created_by || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Created At">{formatUtcDateTime(invoice.created_at)}</Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* Client & Event Information */}
      <Descriptions title="Client & Event Information" bordered column={1} labelStyle={{ width: '150px' }}>
        <Descriptions.Item label="Client Name">{invoice.client_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Site Name">{invoice.site_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Zone">{invoice.zone_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Event Name">{invoice.event_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Event Type">{invoice.event_type || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Service Type">{invoice.service_type || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Event Date">{invoice.event_date || 'N/A'}</Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* Weather Details */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
        <Typography.Title level={5} style={{ margin: 0 }}>Weather Details</Typography.Title>
        <Button
          type="secondary"
          icon={<DownloadOutlined />}
          loading={loadingPdf}
          onClick={() => onDownloadForecastPdf(invoice.forecast.forecast_id)}
        >
          Download PDF
        </Button>
      </div>
      <Descriptions bordered column={1} labelStyle={{ width: '150px' }}>
        <Descriptions.Item label="Station Name">{invoice.forecast.station_name || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Country">{invoice.forecast.country || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Forecast Date">{invoice.forecast.date || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Forecast">{invoice.forecast.forecast || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Low Temp">{invoice.forecast.low_temp}°C</Descriptions.Item>
        <Descriptions.Item label="High Temp">{invoice.forecast.high_temp}°C</Descriptions.Item>
        <Descriptions.Item label="Forecasted Accumulation">{invoice.forecast.forecast_accum} cm</Descriptions.Item>
        <Descriptions.Item label="Actual Accumulation">
          {invoice.forecast.actual_accum != null ? (
            `${invoice.forecast.actual_accum} cm`
          ) : (
            <Tag color="red">Weather Data Incomplete</Tag>
          )}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      {/* Service Histories */}
      {renderServiceHistories()}
    </>
  );
};

export default InvoiceDetails;