// SitesPage.js
import React, { useState, useEffect } from 'react';
import { Typography, Box, Card, CardContent, Snackbar } from '@mui/material';
import { Search as SearchIcon} from '@mui/icons-material';
import { ConfigProvider, Table, Button, Modal, Input, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { fetchSites, fetchSiteById, updateSiteContract } from '../api';
import { useConstants } from '../context/ConstantsContext';
import SideMenu from '../components/SideMenu';


const ContractDataForm = ({ site, onUpdate }) => {
  const constants = useConstants(); 
  const [formData, setFormData] = useState({
    notification_email: site.notification_email,
    salting_contract_type: site.salting_contract_type || 'Event Assigned',
    snow_contract_type: site.snow_contract_type || 'Event Assigned',
    salting_amount: site.salting_amount || 500,
    saltings_included: site.saltings_included || 20,
    saltings_performed: site.saltings_performed || 0,
    per_accum_client_rate: site.per_accum_client_rate || 75,
    per_accum_subcontractor_rate: site.per_accum_subcontractor_rate || 50,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <span
        style={{
          width: '100%',
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          marginLeft: '5px',
          color: '#4a4a4a',
        }}
      >
        {'Notification Email (overrides client contact for notification emails)'}
      </span>
      <Input
        fullWidth
        name="notification_email"
        value={formData.notification_email}
        onChange={handleChange}
        placeholder="Notification Email (optional)"
        style={{ marginBottom: '10px' }}
      />
      <span
        style={{
          width: '100%',
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          marginLeft: '5px',
          color: '#4a4a4a',
        }}
      >
        Salting Contract Type
      </span>
      <Select
        name="salting_contract_type"
        value={
          formData.salting_contract_type === 'Event Assigned'
            ? undefined
            : formData.salting_contract_type
        }
        onChange={(value) =>
          setFormData({ ...formData, salting_contract_type: value })
        }
        options={[
          { value: constants.contract_type.per_visit, label: constants.contract_type.per_visit },
          { value: constants.contract_type.flat_rate, label: constants.contract_type.flat_rate },
          { value: constants.contract_type.all_inclusive, label: constants.contract_type.all_inclusive },
        ]}
        style={{ width: '100%', marginBottom: '10px' }}
        placeholder="Salting Contract Type"
      />
      <span
        style={{
          width: '100%',
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          marginLeft: '5px',
          color: '#4a4a4a',
        }}
      >
        Snow Contract Type
      </span>
      <Select
        name="snow_contract_type"
        value={
          formData.snow_contract_type === 'Event Assigned'
            ? undefined
            : formData.snow_contract_type
        }
        onChange={(value) =>
          setFormData({ ...formData, snow_contract_type: value })
        }
        options={[
          { value: constants.contract_type.hourly, label: constants.contract_type.hourly },
          { value: constants.contract_type.accum, label: constants.contract_type.accum },
        ]}
        style={{ width: '100%', marginBottom: '10px' }}
        placeholder="Snow Contract Type"
      />
      <span
        style={{
          width: '100%',
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          marginLeft: '5px',
          color: '#4a4a4a',
        }}
      >
        Salting Amount
      </span>
      <Input
        fullWidth
        name="salting_amount"
        value={formData.salting_amount}
        onChange={handleChange}
        type="number"
        placeholder="Salting Amount"
        style={{ marginBottom: '10px' }}
      />
      <span
        style={{
          width: '100%',
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          marginLeft: '5px',
          color: '#4a4a4a',
        }}
      >
        Saltings Included
      </span>
      <Input
        fullWidth
        name="saltings_included"
        value={formData.saltings_included}
        onChange={handleChange}
        type="number"
        placeholder="Saltings Included"
        style={{ marginBottom: '10px' }}
      />
      <span
        style={{
          width: '100%',
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          marginLeft: '5px',
          color: '#4a4a4a',
        }}
      >
        Saltings Performed
      </span>
      <Input
        fullWidth
        name="saltings_performed"
        value={formData.saltings_performed}
        onChange={handleChange}
        type="number"
        placeholder="Saltings Performed"
        style={{ marginBottom: '10px' }}
      />
      <span
        style={{
          width: '100%',
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          marginLeft: '5px',
          color: '#4a4a4a',
        }}
      >
        {`Per ${site.country === constants.country.canada ? 'CM' : 'Inch'} (Client)`}
      </span>
      <Input
        fullWidth
        name="per_accum_client_rate"
        value={formData.per_accum_client_rate}
        onChange={handleChange}
        type="number"
        placeholder={`Per ${site.country === constants.country.canada ? 'CM' : 'Inch'} (Client)`}
        style={{ marginBottom: '10px' }}
      />
      <span
        style={{
          width: '100%',
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          marginLeft: '5px',
          color: '#4a4a4a',
        }}
      >
        {`Per ${site.country === constants.country.canada ? 'CM' : 'Inch'} (Subcontractor)`}
      </span>
      <Input
        fullWidth
        name="per_accum_subcontractor_rate"
        value={formData.per_accum_subcontractor_rate}
        onChange={handleChange}
        type="number"
        placeholder={`Per ${site.country === constants.country.canada ? 'CM' : 'Inch'} (Subcontractor)`}
        style={{ marginBottom: '10px' }}
      />
      <Button block style={{ marginBottom: '20px' }} onClick={handleSubmit}>
        Update
      </Button>
    </form>
  );
};

const SiteDetailsView = ({ site }) => {
  return (
    <div>
      <Typography variant="h6">{site.site_name}</Typography>
      <Typography>Client: {site.client_name}</Typography>
      <Typography>Address: {site.street_address}</Typography>
      <Typography>City: {site.city}</Typography>
      <Typography>Province/State: {site.province_state}</Typography>
      <Typography>Postal/Zip Code: {site.postal_zip_code}</Typography>
      <Typography>Country: {site.country}</Typography>
    </div>
  );
};

const SitesPage = () => {
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [error, setError] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [contractView, setContractView] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('site_id');

  useEffect(() => {
    loadSites();
  }, []);

  useEffect(() => {
    const filteredData = sites.filter(site => {
      const siteName = site.site_name || 'N/A';
      const location = site.city || 'N/A';
      const client = site.client_name || 'N/A';
  
      const routes = site.routes_details
        ? site.routes_details.map((r) => r.route_name).join(', ').toLowerCase()
        : '';
      const zones = site.routes_details
        ? site.routes_details.map((r) => r.zone_name).join(', ').toLowerCase()
        : '';
  
      return (
        siteName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        location.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.toLowerCase().includes(searchQuery.toLowerCase()) ||
        routes.includes(searchQuery.toLowerCase()) ||
        zones.includes(searchQuery.toLowerCase())
      );
    });
  
    setFilteredSites(filteredData);
  }, [searchQuery, sites]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedSites = filteredSites.sort((a, b) => {
    const aValue = (a[orderBy] ?? 'N/A').toString();
    const bValue = (b[orderBy] ?? 'N/A').toString();
  
    return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });

  const loadSites = async () => {
    try {
      const data = await fetchSites();
      setSites(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSiteSelect = async (siteId) => {
    try {
      const siteData = await fetchSiteById(siteId);
      setSelectedSite(siteData);
      setContractView(false); // Ensure viewing details by default
    } catch (error) {
      console.error('Error fetching site data:', error);
    }
  };

  const handleEditContract = async (siteId) => {
    try {
      const siteData = await fetchSiteById(siteId);
      setSelectedSite(siteData);
      setContractView(true); // Directly open the contract edit form
    } catch (error) {
      console.error('Error fetching site data:', error);
    }
  };

  const handleContractUpdate = async (updatedContractData) => {
    try {
      await updateSiteContract(selectedSite.site_id, updatedContractData);
      setSnackbarOpen(true);
      setSnackbarMessage('Site Contract updated successfully');
      await loadSites();
      setSelectedSite(null);
    } catch (error) {
      console.error('Error updating site contract:', error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    {
      title: 'Site Name',
      dataIndex: 'site_name',
      key: 'site_name',
      sorter: true,
      sortOrder: orderBy === 'site_name' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('site_name') }),
    },
    {
      title: 'Location',
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      sortOrder: orderBy === 'city' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('city') }),
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      sorter: true,
      sortOrder: orderBy === 'client_name' && order,
      onHeaderCell: () => ({ onClick: () => handleRequestSort('client_name') }),
    },
    {
      title: 'Route',
      dataIndex: 'routes_details',
      key: 'routes_details',
      render: (routes) => routes.map((r) => r.route_name).join(', '),
    },
    {
      title: 'Zone',
      dataIndex: 'routes_details',
      key: 'routes_details',
      render: (routes) => routes.map((r) => r.zone_name).join(', '),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, record) => (
        <EditOutlined
          onClick={(e) => {
            e.stopPropagation();
            handleEditContract(record.site_id);
          }}
        />
      ),
    },
  ];

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F57D20',
        },
      }}
    >
      <Box display="flex" style={{ width: '98vw', marginTop: '20px' }}>
        <Box sx={{ width: '250px', flexShrink: 0 }}>
          <SideMenu />
        </Box>
        <Box flexGrow={1}>
          <Card style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <CardContent>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="h5" component="div">
                  Sites
                </Typography>
                <Input
                  type="text"
                  placeholder="Search Sites"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{ width: '250px' }}
                  prefix={ <SearchIcon style={{ color: "#d9d9d9" }} fontSize="small" /> }
                />
              </Box>
              <Table
                columns={columns}
                dataSource={sortedSites}
                pagination={false}
                onRow={(record) => ({
                  onClick: () => handleSiteSelect(record.site_id),
                  style: { cursor: 'pointer' },
                })}
                style={{ marginTop: '20px' }}
              />
              {selectedSite && (
                <Modal
                  open={Boolean(selectedSite)}
                  onClose={() => setSelectedSite(null)}
                  onCancel={() => setSelectedSite(null)}
                  title="Site Details"
                  centered
                  footer={[
                    <Button
                      key="back"
                      onClick={() => setContractView(!contractView)}
                    >
                      {contractView
                        ? 'View Site Details'
                        : 'Edit Contract Data'}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => setSelectedSite(null)}
                    >
                      Close
                    </Button>,
                  ]}
                >
                  {contractView ? (
                    <ContractDataForm
                      site={selectedSite}
                      onUpdate={handleContractUpdate}
                    />
                  ) : (
                    <SiteDetailsView site={selectedSite} />
                  )}
                </Modal>
              )}
            </CardContent>
          </Card>
          <Snackbar
              open={snackbarOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
            />
        </Box>
      </Box>
    </ConfigProvider>
  );
};

export default SitesPage;