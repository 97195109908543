import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Input, Button, ConfigProvider } from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { Typography, Snackbar, Alert } from '@mui/material';
import '../styles/LoginPage.css';
import { verifyResetPasswordToken, resetPassword } from '../api';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      await verifyResetPasswordToken(token);
    } catch (error) {
      console.error('Token verification error:', error);
      setNotificationMessage(`Error verifying token: ${error.message}. Please request a new password reset.`);
      setNotificationSeverity('error');
      setNotificationOpen(true);
      setTimeout(() => navigate('/forgot-password'), 5000);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setNotificationMessage('Passwords do not match');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    setIsLoading(true);
    try {
      await resetPassword(token, newPassword);
      setNotificationMessage('Password reset successful. You can now log in with your new password.');
      setNotificationSeverity('success');
      setNotificationOpen(true);
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setNotificationMessage(error.message || 'Error resetting password. Please try again.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#F57D20' } }}>
      <div className="container">
        <motion.div 
          className="main"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: "spring", stiffness: 150, damping: 30 }}
        >
          <div className="textStack">
            <span className="title">Reset Password</span>
            <span className="subtitle">Enter your new password below.</span>
          </div>
          <div className="fieldStack">
            <Input.Password
              prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)', marginRight: '5px' }} />}
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className='textField'
            />
            <Input.Password
              prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)', marginRight: '5px' }} />}
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className='textField'
            />
          </div>
          <Button 
            type="primary" 
            onClick={handleResetPassword} 
            className="submitButton" 
            block
            loading={isLoading}
            disabled={isLoading}
          >
            {isLoading ? 'Resetting...' : 'Reset Password'}
          </Button>
        </motion.div>
        <Snackbar
          open={notificationOpen}
          autoHideDuration={6000}
          onClose={() => setNotificationOpen(false)}
        >
          <Alert onClose={() => setNotificationOpen(false)} severity={notificationSeverity}>
            {notificationMessage}
          </Alert>
        </Snackbar>
      </div>
    </ConfigProvider>
  );
};

export default ResetPasswordPage;