// /utils/stringUtils.js

export const formatCurrency = (amount, country, constants) => {
  const currencyCode = country === constants.country.canada 
    ? 'CAD' 
    : country === constants.country.usa 
    ? 'USD' 
    : '';
  
  return amount ? `$${amount.toFixed(2)}${currencyCode ? ` ${currencyCode}` : ''}` : 'N/A';
};